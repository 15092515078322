import React from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import EventPage from './pages/Event';
import LoginPage from './pages/Login';
import EventID from './pages/Event/EventID';
import { ToastProvider } from 'react-toast-notifications';
import Report from './pages/Event/Report';
import Duplicate from './pages/Event/Duplicate';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './utilities/theme';
import UsersPage from './pages/Users';
import NewUser from './pages/Users/new';
import OrganizationId from './pages/Organizations/OrganizationId/OrganizationId';
import NewOrganization from './pages/Organizations/new';
import OrganizationPage from './pages/Organizations';
import LiveActivityPage from './pages/Liveactivity';
import EmailsPage from './pages/emails';
import { store } from './store';
import { Provider } from 'react-redux';
import UserActivityPage from './pages/Useractivity';
import EventTemplatesIndex from './pages/eventtemplates';
import Security from './pages/Security';
import ChangeLogComponent from './pages/Changelog';
import TicketOrdersIndex from './pages/ticketorders';
import PlatformSettings from './pages/platformsettings';
import PremiumOrders from './pages/premiumorders';

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <ToastProvider placement="bottom-right" autoDismiss={true} autoDismissTimeout={2000}>
            <Router>
              <Switch>
                <Route path="/login">
                  <LoginPage />
                </Route>
                <Route path="/events/:id/duplicate">
                  <Duplicate />
                </Route>
                <Route path="/events/:id/report">
                  <Report />
                </Route>
                <Route path="/events/:id">
                  <EventID />
                </Route>
                <Route path="/events">
                  <EventPage />
                </Route>
                <Route path="/users/new">
                  <NewUser />
                </Route>
                <Route path="/users">
                  <UsersPage />
                </Route>
                <Route path="/organizations/:id">
                  <OrganizationId />
                </Route>
                <Route path="/organizations/new">
                  <NewOrganization />
                </Route>
                <Route path="/organizations">
                  <OrganizationPage />
                </Route>
                <Route path="/liveactivity">
                  <LiveActivityPage />
                </Route>
                <Route path="/useractivity">
                  <UserActivityPage />
                </Route>
                <Route path="/emails">
                  <EmailsPage />
                </Route>
                <Route path="/eventtemplates">
                  <EventTemplatesIndex />
                </Route>
                <Route path="/ticketorders">
                  <TicketOrdersIndex />
                </Route>
                <Route path="/premiumorders">
                  <PremiumOrders />
                </Route>
                <Route path="/changelog">
                  <ChangeLogComponent />
                </Route>
                <Route path="/platformsettings">
                  <PlatformSettings />
                </Route>
                <Route path="/security">
                  <Security />
                </Route>
                <Redirect to="/login" />
              </Switch>
            </Router>
          </ToastProvider>
        </ThemeProvider>
      </Provider>
    </div>
  );
}

export default App;
