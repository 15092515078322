import React from 'react';
import EventTemplate from '../../components/EventTemplate';
import AdminLayout from '../../components/AdminLayout';
import TicketOrders from '../../components/TicketOrders';

const TicketOrdersIndex: React.FC = () => {
  return (
    <AdminLayout>
      <TicketOrders />
    </AdminLayout>
  );
};

export default TicketOrdersIndex;
