import http from './http';
import { EventInterface, EventTemplate, PremiumServiceOrder, PremiumServiceType, Role, TicketOrder, User } from './interfaces';
import axios from 'axios';

interface VystemResponse<T> {
  status: string;
  data: T;
}

interface LoginReturnData {
  access_token: string;
  role: Role;
  user: User;
}

export interface LoginProps {
  email: string;
  password: string;
}

export const login = (props: LoginProps): Promise<VystemResponse<LoginReturnData>> => {
  return http.post('/auth/login', props).then((res) => res.data);
};

interface GetAllEventsProps {
  onlyFuture: boolean;
  limit: number;
  includePrivate: boolean;
  project: string;
  offset: number;
  excludeBooths: boolean;
  sort: Record<string, number>;
  filter: string;
  filterOwnHost: boolean;
}

export const getAllEvents = (props: Partial<GetAllEventsProps>): Promise<VystemResponse<EventInterface[]>> => {
  return http.post('/event/getall', props).then((res) => res.data);
};

export const deleteEvent = (eventid: string) => {
  return http.post('/event/delete', { eventid }).then((res) => res.data);
};

export const deleteEventUserData = async (eventid: string) => {
  const { data } = await http.delete(`/user/gdpr/${eventid}`);
  return data;
};

export type CreateEventProps = Omit<EventTemplate, 'datetime_created' | 'datetime_deleted' | '_id'>;

export const createEventTemplate = async (props: Partial<CreateEventProps>): Promise<EventTemplate> => {
  const { data } = await http.post(`/eventtemplate/`, props);
  return data;
};

export const fileUpload = async (file: string | Blob, onProgress?: (p: number) => void) => {
  const formData = new FormData();
  formData.append('file0', file);
  const { data } = await http.post('/file/upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (progressEvent) => {
      console.log('Upload progress event received ', progressEvent);
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (onProgress) onProgress(percentCompleted);
    },
  });
  return data;
};

export const deleteEventTemplate = async (id: string): Promise<EventTemplate> => {
  const { data } = await http.delete(`/eventtemplate/${id}`);
  return data;
};

export const updateEventTemplate = async (id: string, props: Partial<CreateEventProps>): Promise<EventTemplate> => {
  const { data } = await http.patch(`/eventtemplate/${id}`, props);
  return data;
};

export const getEventAmount = async () => {
  const { data } = await http.get(`event/amount`);
  return data;
};

export const getDeletedMeetingrooms = async (eventid: string) => {
  const { data } = await axios.get(`/event/${eventid}/meetingrooms/deleted`);
  return data;
};

export const restoreMeeting = async (meetingroomid: string) => {
  const { data: meeting } = await axios.post('/meeting/restore', { meetingroomid });
  return meeting;
};

export const getAllTicketOrders = async (): Promise<TicketOrder[]> => {
  const { data: data } = await http.get(`/ticket/order/all`);
  return data.data;
};

export const deleteInvoiceService = async (orderId: string): Promise<null> => {
  const { data: data } = await http.delete(`/ticket/order/${orderId}/invoice`);
  return data.data;
};

export const generateInvoiceService = async (orderId: string, sendInvoiceViaMail: boolean): Promise<null> => {
  const { data: data } = await http.post(`/ticket/order/${orderId}/invoice`, { preventmail: !sendInvoiceViaMail });
  return data.data;
};

export const updateBillingDataService = async (orderId: string, billingData: TicketOrder) => {
  await http.patch(`ticket/order/${orderId}/billingData`, billingData);
};

export const payOrderManually = async (orderId: string) => {
  await http.patch(`ticket/order/pay/manually/${orderId}`);
};

export const getPremiumServiceOrders = async () => {
  const { data: data } = await http.get(`/subscription/upgrade/orders`);
  return data.data;
};

export const updatePremiumServiceOrderStatus = async (orderId: string, updateObj: unknown) => {
  await http.patch(`/subscription/upgrade/order/${orderId}`, updateObj);
};

export const togglePremiumFeature = async (event: string, orderId: string, service: PremiumServiceType, featureIsActive: boolean) => {
  await http.post(`/subscription/upgrade/order/${orderId}`, { event, service, featureIsActive });
};
