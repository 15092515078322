import { Button, CircularProgress, Fab, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Autorenew, CancelScheduleSend, Search, Send } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import http from '../../utilities/http';
import AdminLayout from '../../components/AdminLayout';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  topDiv: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  searchPaper: {
    display: 'flex',
    padding: '1rem',
    width: '100%',
  },
  searchInput: {
    fontSize: '20px',
    border: 'none',
    width: '100%',
    outline: 'none',
    marginLeft: '5px',
  },
}));

interface Email {
  _id: string;
  to: string;
  cc: string | null;
  replyTo: string | null;
  datetime_created: Date;
  resendEmailId: string | null;
  datetime_sent: Date | null;
  datetime_canceled: Date | null;
  datetime_failed: Date | null;
  datetime_task_created: Date | null;
  failureReason: string | null;
  failureCount: number;
  datetime_scheduled: Date | null;
  from: string | null;
  eventId: string | null;
  userId: string | null;
  attachments: Record<string, string>[];
  fromName: string | null;
  language: string;
  mailService: string;
  templateId: string | null;
  templateName: string;
  dynamic_template_data: Record<string, string>;
  renderData: Record<string, string>;
  appendRegistrationToRenderData: boolean;
  attendeeInfo: string | null;
  customTemplateId: string | null;
  status_history: { status: string; datetime: Date }[];
}

const EmailsPage = () => {
  const [emails, setEmails] = useState<Email[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();

  const { register, handleSubmit } = useForm();

  useEffect(() => {
    fetchEmails();
  }, []);

  function fetchEmails(data?: { searchValue?: string }) {
    setIsLoading(true);
    http
      .post('/mail/getall', { limit: 100, filter: data?.searchValue })
      .then(({ data }) => {
        setEmails(data.data);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handleCancel(id: string) {
    http.post('/mail/cancel', { mailid: id }).then(() => {
      fetchEmails();
    });
  }

  function handleSendAgain(id: string) {
    http.post('/mail/sendagain', { mailid: id }).then(() => {
      fetchEmails();
    });
  }

  function handleSend(id: string) {
    http.post('/mail/send', { mailid: id }).then(() => {
      fetchEmails();
    });
  }

  function getMailStatus(email: Email) {
    if (email.datetime_sent && email.status_history?.length > 0) {
      return email?.status_history[email?.status_history?.length - 1]?.status || 'Status from Mailservice';
    }
    if (email.datetime_sent && email.status_history?.length === 0) return 'Waiting for mailservice';
    if (email.datetime_canceled) return 'Canceled';
    if (email.datetime_failed) return 'Failed';
    if (email.datetime_task_created) return 'vystem Pipeline';
    if (email.datetime_scheduled) return 'Scheduled';

    return 'No Status';
  }

  return (
    <AdminLayout>
      <div className={classes.topDiv}>
        <p>Hier kann nach E-Mail Status (bounce, click, ..) E-Mail Adresse oder E-Mail ID gesucht werden.</p>
        <form onSubmit={handleSubmit(fetchEmails)}>
          <Paper className={classes.searchPaper}>
            <Search />
            <input {...register('searchValue')} disabled={isLoading} className={classes.searchInput} type="text" />
            <Button disabled={isLoading} type="submit">
              Suchen
            </Button>
          </Paper>
        </form>
      </div>
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Empfänger</TableCell>
                <TableCell align="center">Template</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Uhrzeit gesendet</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {emails.map((email) => (
                <TableRow key={email._id}>
                  <TableCell component="th" scope="row">
                    {email.to} {email.resendEmailId ? '[RESEND]' : ''}
                  </TableCell>
                  <TableCell align="center">{email.templateName}</TableCell>
                  <TableCell align="center">{getMailStatus(email)}</TableCell>
                  <TableCell align="right">{email?.datetime_sent ? new Date(email.datetime_sent).toLocaleString() : ''}</TableCell>
                  <TableCell align="right">
                    {!email.datetime_sent && !email.datetime_canceled && (
                      <>
                        <Tooltip onClick={() => handleCancel(email._id)} title="Cancel Sending">
                          <Fab size="small" color="secondary" aria-label="add">
                            <CancelScheduleSend />
                          </Fab>
                        </Tooltip>
                        <Tooltip onClick={() => handleSend(email._id)} title="Send now">
                          <Fab size="small" color="secondary" aria-label="add">
                            <Send />
                          </Fab>
                        </Tooltip>
                      </>
                    )}
                    <Tooltip onClick={() => handleSendAgain(email._id)} title="Send Again">
                      <Fab size="small" color="secondary" aria-label="add">
                        <Autorenew />
                      </Fab>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </AdminLayout>
  );
};

export default EmailsPage;
