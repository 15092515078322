import React, { useEffect, useState } from 'react';
import { Fab, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowDownwardOutlined, ArrowUpwardOutlined, Delete, Edit, FileCopy, Launch, PeopleOutline, Poll, Search } from '@material-ui/icons';

import { deleteEvent, deleteEventUserData, getAllEvents, getEventAmount } from '../../utilities/servicesNew';
import { Link } from 'react-router-dom';
import { Booth, EventInterface } from '../../utilities/interfaces';
import AdminLayout from '../../components/AdminLayout';
import { VyConfirm } from '../../components/VyConfirm';
import VyModal from '../../components/VyModal';

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  topDiv: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  searchPaper: {
    display: 'flex',
    padding: '1rem',
    width: '100%',
  },
  searchInput: {
    fontSize: '20px',
    border: 'none',
    width: '100%',
    outline: 'none',
    marginLeft: '5px',
  },
}));

const EventPage: React.FC = () => {
  const [events, setEvents] = useState<EventInterface[]>([]);
  const [page, setPage] = useState(0);
  const [pageLength, setPageLength] = useState(25);
  const [userLevel, setUserLevel] = useState(1);
  const [eventAmount, setEventAmount] = useState<number>(0);
  const [usersThatCannotBeDeleted, setUsersThatCannotBeDeleted] = useState<{ email: string; reason: string }[]>([]);
  const classes = useStyles();
  const [sortDirections, setSortDirections] = useState({
    boothCount: false,
    meetingRoomCount: false,
    creationDate: false,
  });

  const reqData = {
    onlyFuture: false,
    includePrivate: true,
    sort: { datetime_created: -1 },
    limit: pageLength,
    excludeBooths: false,
    population: { path: 'organization' },
    offset: page * pageLength,
  };

  useEffect(() => {
    setUserLevel(parseInt(localStorage.getItem('role') as string));
    getEventAmount().then((res) => setEventAmount(res.data));
    fetchEvents();
  }, []);

  async function handleSearchSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const formProps = Object.fromEntries(formData);
    const req = await getAllEvents({ ...reqData, ...formProps });
    setEvents(req.data);
  }

  // sorting
  function sortByBoothCount(asc: boolean) {
    setSortDirections({ ...sortDirections, boothCount: !sortDirections.boothCount });
    setEvents(
      [...events].sort((a, b) => {
        const result = (a.booths?.length || 0) === (b.booths?.length || 0) ? 0 : (a.booths?.length || 0) > (b.booths?.length || 0) ? 1 : -1;
        return !asc ? result : result * -1;
      })
    );
  }

  function sortByMeetingRoomCount(asc: boolean) {
    setSortDirections({ ...sortDirections, meetingRoomCount: !sortDirections.meetingRoomCount });
    setEvents(
      [...events].sort((a, b) => {
        const aRooms = calcMeetingrooms(a?.booths || []);
        const bRooms = calcMeetingrooms(b?.booths || []);
        const result = (aRooms || 0) === (bRooms || 0) ? 0 : (aRooms || 0) > (bRooms || 0) ? 1 : -1;
        return !asc ? result : result * -1;
      })
    );
  }

  function calcMeetingrooms(booths: Booth[]) {
    if (!booths || booths.length === 0) return 0;
    return booths.map((entry) => entry.meetingrooms?.filter((entry) => !entry?.datetime_deleted).length).reduce((acc, cv) => acc + cv);
  }

  function sortByCreationDate(asc: boolean) {
    setSortDirections({ ...sortDirections, creationDate: !sortDirections.creationDate });
    setEvents(
      [...events].sort((a, b) => {
        const result = (a.datetime_created || 0) === (b.datetime_created || 0) ? 0 : (a.datetime_created || 0) > (b.datetime_created || 0) ? 1 : -1;
        return !asc ? result : result * -1;
      })
    );
  }

  // sorting ends

  async function fetchEvents() {
    const req = await getAllEvents({ ...reqData, filterOwnHost: userLevel !== 0 });
    setEvents(req.data);
  }

  async function handleDelete(_id: string) {
    await deleteEvent(_id);
  }

  async function handleDeleteUserData(_id: string) {
    const data = await deleteEventUserData(_id);
    setUsersThatCannotBeDeleted(data.usersThatCannotBeDeleted);
  }

  return (
    <AdminLayout>
      <div className={classes.topDiv}>
        <Paper className={classes.searchPaper}>
          <p>
            Zum Filtern nach Events die einen Namen haben folgenden REGEX suchen: <i>^(?!\s*$).+</i>
          </p>
        </Paper>
      </div>
      <form onSubmit={handleSearchSubmit} className={classes.topDiv}>
        <Paper className={classes.searchPaper}>
          <Search />
          <input name="filter" className={classes.searchInput} type="text" />
        </Paper>
      </form>
      <VyModal open={usersThatCannotBeDeleted.length > 0} onClose={() => setUsersThatCannotBeDeleted([])} title="These users cannot be deleted">
        {(onClose) => (
          <div className="p-4 flex flex-col space-y-2">
            {usersThatCannotBeDeleted.map((user) => (
              <div className="flex flex-row space-x-2">
                <b>{user.email}</b>
                <p>{user.reason}</p>
              </div>
            ))}
          </div>
        )}
      </VyModal>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="center">Host</TableCell>
              <TableCell align="center">Host hat x Stände</TableCell>
              <TableCell align="center" onClick={() => sortByBoothCount(sortDirections.boothCount)} style={{ cursor: 'pointer' }}>
                Anzahl Booths {!sortDirections.boothCount ? <ArrowDownwardOutlined /> : <ArrowUpwardOutlined />}
              </TableCell>
              <TableCell align="center" onClick={() => sortByMeetingRoomCount(sortDirections.meetingRoomCount)} style={{ cursor: 'pointer' }}>
                Anzahl 1:1 Meetings {!sortDirections.meetingRoomCount ? <ArrowDownwardOutlined /> : <ArrowUpwardOutlined />}
              </TableCell>
              <TableCell align="center" onClick={() => sortByCreationDate(sortDirections.creationDate)} style={{ cursor: 'pointer' }}>
                Erstell Datum {!sortDirections.creationDate ? <ArrowDownwardOutlined /> : <ArrowUpwardOutlined />}
              </TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events?.map((event) => (
              <TableRow key={event._id}>
                <TableCell component="th" scope="row">
                  {event.name}
                </TableCell>
                <TableCell align="right">
                  <Link to={`/organizations/${event?.organization?._id}`}>{event?.organization?.name}</Link>
                </TableCell>
                <TableCell align="left">{event?.organization?.booths?.length || 0}</TableCell>
                <TableCell align="center">{event?.booths?.length || 0}</TableCell>
                <TableCell align="center">{calcMeetingrooms(event?.booths || [])}</TableCell>
                <TableCell align="center">{new Date(event.datetime_created).toLocaleString()}</TableCell>
                <TableCell align="center">{event.status}</TableCell>
                <TableCell align="right">
                  <a target="_blank" href={`https://app.vystem.io/event/${event.slug}/live`}>
                    <Tooltip style={{ marginRight: '1rem' }} title="Open">
                      <Fab size="small" color="secondary" aria-label="add">
                        <Launch />
                      </Fab>
                    </Tooltip>
                  </a>
                  <Link to={`/events/${event._id}`}>
                    <Tooltip style={{ marginRight: '1rem' }} title="Edit">
                      <Fab size="small" color="secondary" aria-label="edit">
                        <Edit />
                      </Fab>
                    </Tooltip>
                  </Link>
                  <Link to={`/events/${event._id}/duplicate`}>
                    <Tooltip style={{ marginRight: '1rem' }} title="Duplicate">
                      <Fab size="small" color="secondary" aria-label="edit">
                        <FileCopy />
                      </Fab>
                    </Tooltip>
                  </Link>

                  <VyConfirm
                    message="Are you sure you want to delete all user data related to this event? This action is irreversible"
                    onConfirm={() => handleDeleteUserData(event._id)}>
                    <Tooltip style={{ marginRight: '1rem' }} title="Delete All user data">
                      <Fab size="small" aria-label="delete">
                        <PeopleOutline color="error" />
                      </Fab>
                    </Tooltip>
                  </VyConfirm>

                  <Tooltip onClick={() => handleDelete(event._id)} style={{ marginRight: '1rem' }} title="Delete">
                    <Fab size="small" color="secondary" aria-label="delete">
                      <Delete />
                    </Fab>
                  </Tooltip>
                  {/*<Link to={`/events/${event._id}/report`}>
                    <Tooltip style={{ marginRight: '1rem' }} title="Report">
                      <Fab size="small" color="secondary" aria-label="report">
                        <Poll />
                      </Fab>
                    </Tooltip>
                 </Link>*/}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TablePagination
              count={eventAmount}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              rowsPerPage={pageLength}
              page={page}
              onPageChange={(_, n) => {
                setPage(n);
              }}
              onRowsPerPageChange={(e) => {
                setPageLength(parseInt(e.target.value, 10));
                setPage(0);
              }}
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </AdminLayout>
  );
};

export default EventPage;
