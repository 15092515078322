import { ErrorMessage } from '@hookform/error-message';
import { Button, Container, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { login } from '../../utilities/servicesNew';
import { login as localLogin } from '../../utilities/auth';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20%',
  },
  logoHolder: {
    margin: theme.spacing(1),
    marginBottom: '20px',
    maxWidth: '40%',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  textCenter: {
    textAlign: 'center',
  },
}));

export interface LoginProps {
  email: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const [msg, setMsg] = useState('');
  const history = useHistory();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginProps>();
  const classes = useStyles();

  async function submit(props: LoginProps) {
    props.email = props.email.toLowerCase().trim();
    try {
      const { data } = await login(props);
      const { access_token, role } = data;
      if (role.name !== 'PLATFORM_ADMIN') {
        setMsg('You are not an platform Admin');
        return;
      }
      localLogin(access_token, role.level);
      history.push('/events');
    } catch (err) {
      console.log(err);
      setMsg('Some error occurred, see console');
    }
  }

  return (
    <>
      <Container component="main" className={classes.container} maxWidth="xs">
        <div className={classes.paper}>
          <p className="text-gray-800 font-bold text-lg my-3">Admin Panel</p>

          <form className={classes.form} onSubmit={handleSubmit(submit)} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              type="email"
              autoComplete="email"
              color="secondary"
              autoFocus
              {...register('email', {
                required: 'Dieses Feld ist verpflichtend.',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Ungültige E-Mail Adresse',
                },
              })}
            />
            <ErrorMessage errors={errors} name="email" />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Password"
              type="password"
              id="password"
              color="secondary"
              autoComplete="current-password"
              {...register('password', { required: 'Dieses Feld ist verpflichtend.' })}
            />
            <ErrorMessage errors={errors} name="password" />
            <Button fullWidth type="submit" variant="contained" color="secondary" className={classes.submit}>
              Login
            </Button>
            <p id="error" className={classes.textCenter}>
              {msg}
            </p>
          </form>
        </div>
      </Container>
    </>
  );
};

export default LoginPage;
