import { ErrorMessage } from '@hookform/error-message';
import { Button, Paper, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import http from '../../utilities/http';
import AdminLayout from '../../components/AdminLayout';
import { Promotion } from '../../utilities/interfaces';
import { AdBanner } from '../../components/PlatformSettings/adbanner';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: '500px',
    width: '100%',
    padding: '1rem',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {},
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const PlatformSettings = () => {
  const classes = useStyles();

  const [promotion, setPromotion] = useState<Promotion>();

  async function fetch() {
    try {
      const config = await http.post('/config/get', { path: 'frontend' });

      setPromotion(config.data.data.promotion);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    fetch();
  }, []);

  return (
    <AdminLayout>
      <div className={classes.center}>
        <Paper className={classes.paper}>{promotion && <AdBanner promotion={promotion} onFetch={fetch} />}</Paper>
      </div>
    </AdminLayout>
  );
};

export default PlatformSettings;
