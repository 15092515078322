import { Tooltip } from '@material-ui/core';
import React from 'react';

/**
 * this component is build to avoid .toString() conversions in the DOM
 * e.g. <Tooltip title={t('tooltip-registration-accepted').toString()}>
 */

interface VyTooltipProps {
  title?: string;
  children: React.ReactElement;
}

const VyTooltip: React.FC<VyTooltipProps> = (props) => {
  const { title, children } = props;

  return title ? (
    <Tooltip title={title}>
      <span>{children}</span>
    </Tooltip>
  ) : (
    children
  );
};

export default VyTooltip;
