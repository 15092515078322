import { ErrorMessage } from '@hookform/error-message';
import { Button, Paper, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import http from '../../utilities/http';
import AdminLayout from '../../components/AdminLayout';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: '500px',
    width: '100%',
    padding: '1rem',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {},
  },
  horizontal: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      maxWidth: '47%',
    },
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  previewImage: {
    maxWidth: '100%',
    margin: '0.5rem 0',
  },
  nativeSelect: {
    padding: '18.5px 14px',
    marginTop: '0.5rem',
    borderRadius: '4px',
  },
  headerImages: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    '& > div': {
      width: '45%',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));

interface ExImages {
  logo?: string;
  headerImages?: string[];
  backgroundImage?: string;
}

const NewUser = () => {
  const [msg, setMsg] = useState('');
  const [roles, setRoles] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const classes = useStyles();

  useEffect(() => {
    http.post('/auth/role/getall').then(({ data }) => {
      setRoles(data.data);
    });
  }, []);

  function createUser(data: Record<string, string>) {
    // console.log(data);
    http
      .post('/user/create', data)
      .then((res) => {
        setMsg('Success!');
      })
      .catch((err) => {
        console.log(err);
        setMsg('Something went wrong, see console.');
      });
  }

  return (
    <AdminLayout>
      <div className={classes.center}>
        <Paper className={classes.paper}>
          <form className={classes.form} onSubmit={handleSubmit(createUser)} noValidate>
            <h1 style={{ textAlign: 'center' }}>New User</h1>
            <TextField
              variant="outlined"
              margin="normal"
              label="Email"
              type="email"
              color="secondary"
              {...register('email', { required: 'This field is required.' })}
            />
            <TextField variant="outlined" margin="normal" label="Name" color="secondary" {...register('name', { required: 'This field is required.' })} />
            <ErrorMessage errors={errors} name="email" />
            <TextField
              variant="outlined"
              margin="normal"
              label="Password"
              type="password"
              color="secondary"
              {...register('password', { required: 'This field is required.' })}
            />
            <ErrorMessage errors={errors} name="password" />
            {roles.length > 0 && (
              <select {...register('role', { required: 'This field is required.' })} defaultValue={roles[0]} className={classes.nativeSelect}>
                {roles.map((role) => (
                  <option key={role} value={role}>
                    {role}
                  </option>
                ))}
              </select>
            )}
            <Button type="submit" fullWidth variant="contained" color="secondary">
              Create User
            </Button>
            <p style={{ textAlign: 'center' }}>{msg}</p>
          </form>
        </Paper>
      </div>
    </AdminLayout>
  );
};

export default NewUser;
