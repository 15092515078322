import {
  AppBar,
  Button,
  Container,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AlternateEmail, ConfirmationNumber, Event, ExitToApp, FilterFrames, Group, Receipt, RecordVoiceOver, Security, Settings } from '@material-ui/icons';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import jwt_decode from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
// import setAxiosInterceptors from '../utils/axiosInterceptors';
import { delete_cookie } from '../utilities/helper';
import { Link, useHistory } from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
  fullHeight: {
    minHeight: 'calc(100vh - 130px)',
  },
}));

const AdminLayout: React.FC = ({ children }) => {
  const classes = useStyles();
  const router = useHistory();
  const [userLevel, setUserLevel] = useState(1);
  const [email, setEmail] = useState('');
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  function logout() {
    localStorage.clear();
    delete_cookie('sde.token');
    delete_cookie('sde.mdata');
    delete_cookie('sde.catok');
    router.push('/login');
  }

  useEffect(() => {
    setUserLevel(parseInt(localStorage.getItem('role') as string));
    if (!userLevel || userLevel >= 2) logout();

    const jwt = localStorage.getItem('access_token');
    if (jwt) {
      const token: { email: string } = jwt_decode(jwt);
      setEmail(token.email);
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>vystem.io | AdminPanel</title>
      </Helmet>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar color="secondary" position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
          <Toolbar className={classes.toolbar}>
            <IconButton edge="start" aria-label="open drawer" onClick={handleDrawerOpen} className={clsx(classes.menuButton, open && classes.menuButtonHidden)}>
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
              Dashboard
            </Typography>
            <p>{email} &nbsp;&nbsp;</p>
            <Button variant="contained" onClick={logout}>
              Abmelden
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" color="secondary" classes={{ paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose) }} open={open}>
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <Link to="/events">
              <ListItem button>
                <ListItemIcon>
                  <Event />
                </ListItemIcon>
                <ListItemText primary="Events" />
              </ListItem>
            </Link>
            {userLevel === 0 && (
              <Link to="/organizations">
                <ListItem button>
                  <ListItemIcon>
                    <RecordVoiceOver />
                  </ListItemIcon>
                  <ListItemText primary="Orgs" />
                </ListItem>
              </Link>
            )}
            <Link to="/users">
              <ListItem button>
                <ListItemIcon>
                  <Group />
                </ListItemIcon>
                <ListItemText primary="User" />
              </ListItem>
            </Link>

            <Link to="/emails">
              <ListItem button>
                <ListItemIcon>
                  <AlternateEmail />
                </ListItemIcon>
                <ListItemText primary="Emails" />
              </ListItem>
            </Link>
            <Link to="/eventtemplates">
              <ListItem button>
                <ListItemIcon>
                  <FilterFrames />
                </ListItemIcon>
                <ListItemText primary="Event templates" />
              </ListItem>
            </Link>
            <Link to="/ticketorders">
              <ListItem button>
                <ListItemIcon>
                  <ConfirmationNumber />
                </ListItemIcon>
                <ListItemText primary="Ticket orders" />
              </ListItem>
            </Link>
            <Link to="/premiumorders">
              <ListItem button>
                <ListItemIcon>
                  <Receipt />
                </ListItemIcon>
                <ListItemText primary="Premium orders" />
              </ListItem>
            </Link>
            {/*<Link to="/changelog">
              <ListItem button>
                <ListItemIcon>
                  <ChangeHistory />
                </ListItemIcon>
                <ListItemText primary="Changelog" />
              </ListItem>
            </Link>*/}
            <Divider />
            <Link to="/platformsettings">
              <ListItem button>
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                <ListItemText primary="Plattformeinstellungen" />
              </ListItem>
            </Link>
            <Link to="/security">
              <ListItem button>
                <ListItemIcon>
                  <Security />
                </ListItemIcon>
                <ListItemText primary="Sicherheit" />
              </ListItem>
            </Link>
            <ListItem button onClick={logout}>
              <ListItemIcon>
                <ExitToApp />
              </ListItemIcon>
              <ListItemText primary="Abmelden" />
            </ListItem>
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          {children ? (
            <Container maxWidth="lg" className={classes.container}>
              {children}
            </Container>
          ) : (
            <></>
          )}
        </main>
      </div>
    </>
  );
};

export default AdminLayout;
