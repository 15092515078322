import {
  CircularProgress,
  Fab,
  FormControlLabel,
  FormGroup,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Poll } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import http from '../../utilities/http';
import { LiveActivity } from '../../utilities/interfaces';
import AdminLayout from '../../components/AdminLayout';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    maxHeight: '80%',
    overflow: 'auto',
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
  topDiv: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  searchPaper: {
    padding: '0 1rem',
    width: '100%',
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    minWidth: '400px',
    padding: theme.spacing(2, 4, 3),
  },
}));

type Timer = ReturnType<typeof setTimeout>;

const LiveActivityPage = () => {
  const classes = useStyles();
  const [data, setData] = useState<LiveActivity[]>([]);
  const [autorefresh, setAutoRefresh] = useState<boolean>(false);
  const [participantCount, setParticipantCount] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [modalId, setModalId] = useState<string | null>(null);
  const [refreshInterval, setRefreshInterval] = useState<Timer | null>(null);

  async function refresh() {
    if (isRefreshing) return;
    try {
      setIsRefreshing(true);
      const data = await http.post('/reporting/live/runningmeetings', {
        showUserCount: participantCount,
      });
      setData(data.data.data);
    } catch (err) {
      console.error(err);
    }
    setIsRefreshing(false);
  }

  function reloadAutoRefresh() {
    if (autorefresh) {
      setRefreshInterval(setInterval(() => refresh(), 10000));
    } else {
      if (refreshInterval) clearInterval(refreshInterval);
      setRefreshInterval(null);
    }
  }

  useEffect(() => {
    reloadAutoRefresh();
  }, [autorefresh]);

  // useEffect(() => {if(participantCount === true) refresh()}, [participantCount])

  async function fetch() {
    setLoading(true);
    await refresh();
    setLoading(false);
  }

  function handleParticipantCount(val: boolean) {
    setParticipantCount(val);
  }

  useEffect(() => {
    if (participantCount) {
      reloadAutoRefresh();
      refresh();
    }
  }, [participantCount]);

  useEffect(() => {
    fetch();
  }, []);

  return (
    <AdminLayout>
      <div className={classes.topDiv}>
        <Paper className={classes.searchPaper}>
          <h3>Live Activity</h3>
        </Paper>
      </div>
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <>
          <FormGroup row>
            <FormControlLabel control={<Switch checked={autorefresh} onChange={() => setAutoRefresh(!autorefresh)} name="checkedA" />} label="Autorefresh" />
            <FormControlLabel
              control={<Switch checked={participantCount} onChange={() => handleParticipantCount(!participantCount)} name="checkedB" />}
              label="Participantcount"
            />
          </FormGroup>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Event</TableCell>
                  {participantCount && <TableCell>Usercount</TableCell>}
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell component="th" scope="row">
                      {item.name || item.contactperson || item.description}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item.meetingtype}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {item.booth?.event?.name}
                    </TableCell>
                    {participantCount && (
                      <TableCell component="th" scope="row">
                        {item.count}
                      </TableCell>
                    )}
                    <TableCell align="right">
                      <Tooltip
                        style={{ marginRight: '1rem' }}
                        title="Report"
                        onClick={() => {
                          setModalId(item._id);
                        }}>
                        <Fab size="small" color="secondary" aria-label="add">
                          <Poll />
                        </Fab>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </AdminLayout>
  );
};

export default LiveActivityPage;
