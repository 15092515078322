import { delete_cookie } from './helper';

const ACCESS_TOKEN = 'access_token';
const ROLE = 'role';

export const setToken = (token: string) => {
  localStorage.setItem(ACCESS_TOKEN, token);
};

export const getToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const removeToken = () => {
  localStorage.clear();
};

export const logout = () => {
  removeToken();
  delete_cookie('sde.token');
  delete_cookie('sde.mdata');
};

export const login = (token: string, roleLevel: number) => {
  setToken(token);
  localStorage.setItem(ROLE, roleLevel.toString());
};
