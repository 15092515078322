import { useEffect, useState } from 'react';
import { createDisplayLink } from '../../utilities/helper';
import { OrderStatus, PremiumServiceOrder, PremiumServiceType } from '../../utilities/interfaces';
import VyModal from '../VyModal';
import CustomAppStoreApp from './CustomAppStoreApp';
import CustomDomain from './CustomDomain';

interface OrderDetailsModalProps {
  showMore: { modal: boolean; order?: PremiumServiceOrder };
  setShowMore: React.Dispatch<
    React.SetStateAction<{
      modal: boolean;
      order?: PremiumServiceOrder | undefined;
    }>
  >;
}

export default function OrderDetailsModal({ showMore, setShowMore }: OrderDetailsModalProps) {
  const [additionalData, setAdditionalData] = useState<PremiumServiceOrder['additionalData']>();

  function getAdditionalDataValue(dataName: string) {
    return additionalData?.filter((data) => data.name === dataName)[0].value;
  }

  useEffect(() => {
    if (!showMore.order) return;
    setAdditionalData(showMore.order.additionalData);
  }, [showMore]);

  return (
    <VyModal open={showMore.modal} onClose={() => setShowMore({ modal: false })} title="Bestellungsdetails" disableDefaultStyling>
      {() => (
        <div style={{ width: '450px', maxWidth: '100%', maxHeight: '100%' }}>
          {showMore.order?.service === PremiumServiceType.CustomAppstoreApp ? (
            <CustomAppStoreApp getAdditionalDataValue={getAdditionalDataValue} showMore={showMore} />
          ) : showMore.order?.service === PremiumServiceType.CustomDomain ? (
            <CustomDomain getAdditionalDataValue={getAdditionalDataValue} />
          ) : null}
        </div>
      )}
    </VyModal>
  );
}
