import { ErrorMessage } from '@hookform/error-message';
import { Button, Paper, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import http from '../../utilities/http';
import AdminLayout from '../../components/AdminLayout';

interface PasswordChangeProps {
  newPassword: string;
  oldPassword: string;
  confirmPassword: string;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: '500px',
    width: '100%',
    padding: '1rem',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {},
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const Security = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<PasswordChangeProps>();
  const [msg, setMsg] = useState('');
  const classes = useStyles();

  function submit({ oldPassword, newPassword }: PasswordChangeProps) {
    http
      .post('/auth/update', { oldPassword, newPassword })
      .then((res) => {
        setMsg('Success!');
      })
      .catch((err) => {
        setMsg(err?.response?.data?.error || 'Something went wrong!');
      });
  }

  return (
    <AdminLayout>
      <div className={classes.center}>
        <Paper className={classes.paper}>
          <form className={classes.form} onSubmit={handleSubmit(submit)} noValidate>
            <h1 style={{ textAlign: 'center' }}>Passwort ändern</h1>
            <TextField
              variant="outlined"
              margin="normal"
              label="Altes Passwort"
              color="secondary"
              type="password"
              fullWidth
              {...register('oldPassword', {
                required: 'This field is required.',
                minLength: { value: 8, message: 'Min 8 characters.' },
              })}
            />
            <small>
              <ErrorMessage errors={errors} name="oldPassword" />
            </small>
            <TextField
              variant="outlined"
              margin="normal"
              label="Neues Passwort"
              color="secondary"
              fullWidth
              type="password"
              {...register('newPassword', { required: 'This field is required.' })}
            />
            <small>
              <ErrorMessage errors={errors} name="secondary" />
            </small>
            <TextField
              variant="outlined"
              margin="normal"
              label="Neues Passwort wiederholen"
              color="secondary"
              fullWidth
              type="password"
              {...register('confirmPassword', {
                required: 'This field is required.',
                validate: (value) => value === watch('newPassword') || "Passwords don't match.",
              })}
            />
            <small>
              <ErrorMessage errors={errors} name="confirmPassword" />
            </small>
            <Button style={{ marginTop: '1rem' }} type="submit" fullWidth variant="contained" color="secondary">
              Passwort ändern
            </Button>
            <p style={{ textAlign: 'center' }}>{msg}</p>
          </form>
        </Paper>
      </div>
    </AdminLayout>
  );
};

export default Security;
