import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import TemplateCard from './TemplateCard';
import { Autocomplete, Button, MenuItem, Select, TextField } from '@mui/material';
import VyModal from '../VyModal';
import { createDisplayLink } from '../../utilities/helper';
import { useGetAllEventsQuery, useGetAllEventTemplatesQuery } from '../../utilities/rtkquery';
import { CreateEventProps, deleteEventTemplate, fileUpload, updateEventTemplate } from '../../utilities/servicesNew';
import { EventInterface, EventTemplate } from '../../utilities/interfaces';

interface TemplateListProps {
  templates: EventTemplate[];
  refetch: () => void;
}

const TemplateList: React.FC<TemplateListProps> = ({ templates, refetch }) => {
  const [editingTemplate, setEditingTemplate] = useState<number | null>(null);
  const dispatch = useDispatch();
  const { data: events = [], isLoading } = useGetAllEventsQuery({
    onlyFuture: false,
    limit: 1000,
    population: [],
    includePrivate: true,
    project: { slug: 1, name: 1 },
  });

  async function handleImageUpload(files: FileList | null) {
    if (!files || !files[0]) return;
    const file = await fileUpload(files[0]);
    if (editingTemplate === null) return;
    await updateEventTemplate(templates[editingTemplate]._id, { previewImage: file.data.fileId });
    refetch();
  }

  async function updateTemplate(props: Partial<CreateEventProps>) {
    if (editingTemplate === null) return;
    await updateEventTemplate(templates[editingTemplate]._id, props);
    refetch();
  }

  async function handleDelete() {
    if (editingTemplate === null) return;
    await deleteEventTemplate(templates[editingTemplate]._id);
    refetch();
    setEditingTemplate(null);
  }

  if (!templates) return null;

  return (
    <>
      {editingTemplate !== null && (
        <VyModal open={editingTemplate !== null} title="" onClose={() => setEditingTemplate(null)} onSubmit={() => null}>
          {() => (
            <div style={{ minWidth: '500px' }} className="settings-wrapper">
              <div className="flex flex-col items-center">
                {!templates[editingTemplate]?.previewImage && <div className="w-32 h-32 border-dotted border-2 grid place-items-center">Template image</div>}
                {templates[editingTemplate]?.previewImage && (
                  <img src={createDisplayLink(templates[editingTemplate]?.previewImage)} className="w-32 h-32 object-cover object-center" alt="avatar" />
                )}
                <input type="file" onChange={({ target }) => handleImageUpload(target.files)} className="mt-2" />
              </div>
              <div className="flex justify-between items-center">
                <h2 className="font-bold">Name</h2>
                <TextField
                  name="name"
                  defaultValue={templates[editingTemplate]?.name}
                  placeholder="Name"
                  type="text"
                  onBlur={({ target }) => updateTemplate({ name: target.value })}
                />
              </div>
              <div className="flex justify-between items-center">
                <h2 className="font-bold">Description</h2>
                <TextField
                  name="description"
                  defaultValue={templates[editingTemplate]?.description}
                  placeholder="Description"
                  type="text"
                  onBlur={({ target }) => updateTemplate({ description: target.value })}
                />
              </div>
              <div className="flex justify-between items-center mt-2">
                <h2 className="font-bold">Event</h2>
                <Autocomplete
                  disablePortal
                  fullWidth
                  id="event-select"
                  className="my-2"
                  defaultValue={{
                    id: templates[editingTemplate]?.eventId,
                    label: events.find((event) => event._id === templates[editingTemplate]?.eventId)?.name ?? '[No Name]',
                  }}
                  renderInput={(params) => <TextField {...params} variant="outlined" label="Event" />}
                  options={events.map(({ name, _id, slug }) => ({ label: `${name ?? '[No Name]'} (${slug})` ?? slug ?? '', id: _id ?? '' })) ?? []}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(_, value) => updateTemplate({ eventId: value?.id ?? undefined })}
                />
              </div>
              <div className="flex justify-end mt-2">
                <Button onClick={handleDelete}>Delete</Button>
              </div>
            </div>
          )}
        </VyModal>
      )}
      <div className="flex flex-wrap mt-4 p-4">
        {templates.map((template, index) => (
          <TemplateCard eventTemplate={template} className="w-56 mr-4 mb-4" key={template._id} onClick={() => setEditingTemplate(index)} />
        ))}
      </div>
    </>
  );
};

export default TemplateList;
