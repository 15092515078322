import { Fab, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Add, AddToHomeScreen, Delete, Edit, EnhancedEncryption, Mail, PhoneForwarded, Search } from '@material-ui/icons';
import Pagination from '@mui/material/Pagination';
import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Link } from 'react-router-dom';
import { User } from '../../utilities/interfaces';
import VyTooltip from '../../components/VyTooltip';
import http from '../../utilities/http';
import AdminLayout from '../../components/AdminLayout';
import { UserEmailUpdateModal } from '../../components/UserEmailUpdateModal';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
  topDiv: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  searchPaper: {
    display: 'flex',
    padding: '1rem',
    width: '100%',
  },
  searchInput: {
    fontSize: '20px',
    border: 'none',
    width: '100%',
    outline: 'none',
    marginLeft: '5px',
  },
  pagination: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem',
  },
}));

const UsersPage = () => {
  const [users, setUsers] = useState<User[]>([]);
  const { addToast } = useToasts();
  const classes = useStyles();
  const perPage = 30;
  const [filter, setFilter] = useState('');
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [emailUpdateUser, setEmailUpdateUser] = useState<User>();

  useEffect(() => {
    fetchUsers();
  }, [filter, page]);

  function fetchUsers() {
    http
      .post('/user/getall', { filter, offset: page * perPage, limit: perPage, sort: { datetime_created: -1 } })
      .then(({ data }) => {
        setUsers(data.data);
        setTotal(data.total);
      })
      .catch(console.log);
  }

  async function handleDelete(userId: string) {
    try {
      await http.delete(`/user/gdpr/user/${userId}`);
      await fetchUsers();
    } catch (err) {
      console.error(err);
    }
  }

  async function resendUser(userid: string, phone?: boolean) {
    try {
      const data = await http.post('/user/reset', { userid, phone });
      console.log(data);
      fetchUsers();
      addToast('Neues Passwort versandt.', { appearance: 'success' });
    } catch (err) {
      addToast('Fehler.', { appearance: 'error' });
      console.log(err);
    }
  }

  async function resetPassword(userEmail: string) {
    if (!userEmail) return;
    try {
      const data = await http.post('/auth/request', { email: userEmail });
      console.log(data);
      fetchUsers();
      addToast('Passwort Reset E-Mail versendet.', { appearance: 'success' });
    } catch (err) {
      addToast('Fehler.', { appearance: 'error' });
      console.log(err);
    }
  }

  function setUserPassword(userid: string) {
    http
      .post('/user/update', { userid, password: '12345678', setResetToken: true })
      .then(() => {
        addToast('Neues Passwort ist 12345678', { appearance: 'success' });
        fetchUsers();
      })
      .catch(console.log);
  }

  function switchUserMail(userid: string, current: boolean) {
    http
      .post('/user/update', { userid, emailOptIn: !current })
      .then(() => {
        fetchUsers();
      })
      .catch(console.log);
  }

  function handleSearch({ charCode, currentTarget }: React.KeyboardEvent<HTMLInputElement>) {
    if (charCode === 13) {
      setFilter(currentTarget.value);
    }
  }

  return (
    <AdminLayout>
      <UserEmailUpdateModal
        user={emailUpdateUser}
        onClose={() => {
          setEmailUpdateUser(undefined);
          fetchUsers();
        }}
      />
      <div className={classes.topDiv}>
        <Paper className={classes.searchPaper}>
          <Search />
          <input onKeyPress={handleSearch} className={classes.searchInput} type="text" />
        </Paper>
        <div style={{ margin: '0 2rem' }}>
          <Link to="/users/new">
            <VyTooltip title="Add">
              <Fab size="small" color="secondary" aria-label="add">
                <Add />
              </Fab>
            </VyTooltip>
          </Link>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell align="center">Org</TableCell>
              <TableCell align="center">Role</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user._id}>
                <TableCell component="th" scope="row">
                  <div className="flex flex-row w-full">
                    <div className="grow">{user.email}</div>
                    <div className="justify-end">
                      <VyTooltip title="E-Mail ändern">
                        <Fab size="small" onClick={() => setEmailUpdateUser(user)} color="secondary" aria-label="add">
                          <Edit />
                        </Fab>
                      </VyTooltip>
                    </div>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <Link to={`/organizations/${user?.organization?._id}`}>{user?.organization?.name}</Link>
                </TableCell>
                <TableCell align="center">{user.role}</TableCell>
                <TableCell align="right">
                  <VyTooltip title="Neues Passwort für User setzen">
                    <Fab size="small" onClick={() => setUserPassword(user._id)} color="secondary" aria-label="add">
                      <EnhancedEncryption />
                    </Fab>
                  </VyTooltip>
                  <VyTooltip title="Neues Passwort an User per SMS (Kosten)">
                    <Fab size="small" onClick={() => resendUser(user._id, true)} color="secondary" aria-label="add">
                      <PhoneForwarded />
                    </Fab>
                  </VyTooltip>
                  <VyTooltip title="Passwort Reset Token per E-Mail an Nutzer (der Nutzer muss dann sein Passwort neu setzen)">
                    <Fab size="small" onClick={() => resetPassword(user.email)} color="secondary" aria-label="add">
                      <AddToHomeScreen />
                    </Fab>
                  </VyTooltip>
                  <Tooltip onClick={() => handleDelete(user._id)} title="Delete">
                    <Fab size="small" color="secondary" aria-label="add">
                      <Delete />
                    </Fab>
                  </Tooltip>
                  <Tooltip onClick={() => switchUserMail(user._id, user.emailOptIn)} title="E-Mail Opt-In">
                    <Fab size="small" aria-label="add" style={{ background: user.emailOptIn ? 'green' : 'red', color: 'white' }}>
                      <Mail />
                    </Fab>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={classes.pagination}>
          <Pagination count={Math.ceil(total / perPage)} page={page + 1} onChange={(_, page) => setPage(page - 1)} color="secondary" />
        </div>
      </TableContainer>
    </AdminLayout>
  );
};

export default UsersPage;
