interface CustomDomainProps {
  getAdditionalDataValue(dataName: string): string | undefined;
}

export default function CustomDomain({ getAdditionalDataValue }: CustomDomainProps) {
  return (
    <div className="flex gap-8 py-2">
      <div className="flex flex-col gap-6">
        <div>
          <h2 className="mb-2 text-base font-bold">Wunsch-Domain:</h2>
          <span>{getAdditionalDataValue('requestedURL')}</span>
        </div>
      </div>
    </div>
  );
}
