import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useState } from 'react';

interface AsyncSearchFieldProps<T> {
  onOptionSelected?: (option: T | null) => void;
  options: T[];
  onChange?: (text: string) => void;
  searchText: string;
  label: string;
  defaultValue?: T;
  searchTextMinLength?: number;
  placeholder?: string;
}

const AsyncSearchField = <T extends { name: string }>({
  onOptionSelected,
  onChange,
  searchText,
  options,
  label,
  defaultValue,
  searchTextMinLength = 2,
  placeholder,
}: AsyncSearchFieldProps<T>) => {
  const [selected, setSelected] = useState<T | null>(defaultValue || null);
  const loading = searchText.length > searchTextMinLength && options.length === 0;

  function handleChange(option: T | null) {
    if (onOptionSelected) onOptionSelected(option);
    setSelected(option);
  }

  return (
    <Autocomplete
      id="asynchronous-org"
      style={{ width: '100%' }}
      open={searchText.length > searchTextMinLength}
      value={selected}
      onClose={() => onChange?.('')}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      onChange={(e, v) => handleChange(v)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          placeholder={placeholder || ''}
          onChange={(e) => onChange?.(e.target.value)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};
export default AsyncSearchField;
