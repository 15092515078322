import { IconButton } from '@material-ui/core';
import { Restore } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { Meetingroom } from '../../utilities/interfaces';
import { getDeletedMeetingrooms, restoreMeeting } from '../../utilities/servicesNew';
import VyModal from '../VyModal';

interface RestoreMeetingroomModalProps {
  open?: boolean;
  onClose: () => void;
  onRestore?: (id: string) => void;
  eventId: string;
}

export const RestoreMeetingroomModal: React.FC<RestoreMeetingroomModalProps> = ({ open = false, onClose, onRestore, eventId }) => {
  const [meetingrooms, setMeetingrooms] = useState<Meetingroom[]>();
  const { addToast } = useToasts();

  const restoreMeetingroom = (id: string) => {
    restoreMeeting(id)
      .then(() => {
        addToast('Successfully restored meetingroom!', { appearance: 'success' });
        onRestore?.(id);
        onClose?.();
      })
      .catch((e) => {
        console.log(e);
        addToast(e.message, { appearance: 'error' });
      });
  };

  useEffect(() => {
    getDeletedMeetingrooms(eventId)
      .then((res) => {
        setMeetingrooms(res.data);
        console.log(res.data);
      })
      .catch(console.error);
  }, [eventId, open]);

  return (
    <VyModal open={open} onClose={onClose} title="Restore a Meetingroom" maxWidth={800}>
      {() => (
        <div className="w-96">
          {meetingrooms?.map((room) => (
            <div className="flex w-full items-center">
              <div>
                <p>
                  [{room.booth?.name}] {room.name}
                </p>
                <p>{room.contactperson}</p>
              </div>
              <IconButton onClick={() => restoreMeetingroom(room._id)}>
                <Restore />
              </IconButton>
            </div>
          ))}
        </div>
      )}
    </VyModal>
  );
};
