import { CircularProgress, Modal, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { LiveUsersInterface } from '../../utilities/interfaces';
import http from '../../utilities/http';

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    maxHeight: '80%',
    overflow: 'auto',
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
  topDiv: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  searchPaper: {
    padding: '0 1rem',
    width: '100%',
  },
  paper: {
    position: 'absolute',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    minWidth: '400px',
    padding: theme.spacing(2, 4, 3),
  },
}));

interface DetailModalProps {
  onClose: () => void;
  onChange: () => void;
  user: LiveUsersInterface | null;
}

const UserActivityDetails: React.FC<DetailModalProps> = (props) => {
  const { onClose, onChange, user } = props;

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function updateUserName(e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    if (user) {
      console.log(e.target.value);
      try {
        setIsLoading(true);
        const data = await http.post('/user/update', {
          userid: user.user._id,
          name: e.target.value,
        });
        onChange();
        onClose();
      } catch (err) {
        console.error(err);
      }
      setIsLoading(false);
    }
  }

  return (
    <Modal open={user !== undefined && user !== null} onClose={onClose} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
      <div style={modalStyle} className={classes.paper}>
        {isLoading && <CircularProgress />}
        {!isLoading && (
          <>
            <form noValidate autoComplete="off">
              <TextField id="standard-basic" label="Name" defaultValue={user?.user.name} onBlur={(e) => updateUserName(e)} />
            </form>
          </>
        )}
      </div>
    </Modal>
  );
};

export default UserActivityDetails;
