import http from '../../../utilities/http';
export async function createBudgetService(params: Partial<PlatformUsageBudget>) {
  const data = http.post('/sales/platformusage/', params);
  return data;
}

export type AllowedUsageBudgets = 'video' | 'stream' | 'admins' | 'subusers' | 'emailsPerMonth' | 'maxParallelUsers';

export interface UsageSummary {
  currentValue: number;
  budgetValue: number;
  usage: number | null;
}

export type PlatformUsageBudget = {
  _id?: string;
  type: AllowedUsageBudgets;
  organization: string;
  value: number;
  description?: string;
};

export interface OrgUsageSumamry {
  summaries: {
    [key in AllowedUsageBudgets]: UsageSummary;
  };
  hasUsageAlert: boolean;
  allBudgets: PlatformUsageBudget[];
}

export async function fetchOrgUsageSummary(orgId: string) {
  const { data: data } = await http.get(`/sales/platformusage/org/${orgId}`);
  return data.data as OrgUsageSumamry;
}

export async function deleteBudgetService(budgetId: string) {
  const data = http.delete(`/sales/platformusage/${budgetId}`);
  return data;
}
