import { Chip, Fab, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Delete, Edit, Search, Send } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Organization } from '../../utilities/interfaces';
import http from '../../utilities/http';
import AdminLayout from '../../components/AdminLayout';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
  topDiv: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  searchPaper: {
    display: 'flex',
    padding: '1rem',
    width: '100%',
  },
  searchInput: {
    fontSize: '20px',
    border: 'none',
    width: '100%',
    outline: 'none',
    marginLeft: '5px',
  },
}));

const OrganizationPage = () => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const classes = useStyles();

  useEffect(() => {
    fetchOrganizations();
  }, []);

  function fetchOrganizations() {
    http
      .post('/organization/getall')
      .then(({ data }) => {
        setOrganizations(data.data);
      })
      .catch(console.log);
  }

  function handleDelete(organizationid: string) {
    http
      .post('/organization/delete', { organizationid })
      .then(() => {
        fetchOrganizations();
      })
      .catch(console.log);
  }

  function handleCRM(organizationid: string) {
    http
      .post('/organization/push_to_crm', { organizationid })
      .then(() => {
        fetchOrganizations();
      })
      .catch(console.log);
  }

  function handleSearch({ charCode, currentTarget }: React.KeyboardEvent<HTMLInputElement>) {
    if (charCode === 13) {
      http
        .post('/organization/getall', { filter: currentTarget.value })
        .then(({ data }) => {
          setOrganizations(data.data);
        })
        .catch(console.log);
    }
  }

  return (
    <AdminLayout>
      <div className={classes.topDiv}>
        <Paper className={classes.searchPaper}>
          <Search />
          <input onKeyPress={handleSearch} className={classes.searchInput} type="text" />
        </Paper>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="center">User count</TableCell>
              <TableCell align="center">Membership count</TableCell>
              <TableCell align="center">Datetime Created</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organizations.map((org) => (
              <TableRow key={org._id}>
                <TableCell component="th" scope="row">
                  {org.name} {org.isFreeTier ? <Chip color="primary" label="Free Tier" variant="outlined" /> : ''}
                </TableCell>
                <TableCell align="center">{org.users.length}</TableCell>
                <TableCell align="center">{org.memberships.length}</TableCell>
                <TableCell align="center">{org.datetime_created}</TableCell>
                <TableCell align="right">
                  <Tooltip style={{ marginRight: '1rem' }} onClick={() => handleCRM(org._id)} title="Send to CRM">
                    <Fab size="small" color="secondary" aria-label="add">
                      <Send />
                    </Fab>
                  </Tooltip>
                  <Link to={`/organizations/${org._id}`}>
                    <Tooltip style={{ marginRight: '1rem' }} title="Edit">
                      <Fab size="small" color="secondary" aria-label="add">
                        <Edit />
                      </Fab>
                    </Tooltip>
                  </Link>
                  <Tooltip onClick={() => handleDelete(org._id)} title="Delete">
                    <Fab size="small" color="secondary" aria-label="add">
                      <Delete />
                    </Fab>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </AdminLayout>
  );
};

export default OrganizationPage;
