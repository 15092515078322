import { useToasts } from 'react-toast-notifications';
import { createDisplayLink } from '../../utilities/helper';
import { PremiumServiceOrder } from '../../utilities/interfaces';

interface CustomAppStoreAppProps {
  showMore: { modal: boolean; order?: PremiumServiceOrder };
  getAdditionalDataValue(dataName: string): string | undefined;
}

export default function CustomAppStoreApp({ showMore, getAdditionalDataValue }: CustomAppStoreAppProps) {
  const { addToast } = useToasts();

  function handleClick() {
    navigator.clipboard.writeText(getAdditionalDataValue('appTheme')!);
    addToast('Wert in Zwischenablage kopiert', { appearance: 'success' });
  }
  if (!showMore.order) return null;
  return (
    <div className="space-y-4">
      <div className="space-y-3">
        <div>
          <h2 className="mb-2 text-base font-bold">Appname:</h2>
          <span>{getAdditionalDataValue('appName')}</span>
        </div>
        {showMore.order.additionalOptions && showMore.order.additionalOptions?.length > 0 && (
          <div>
            <h2 className="mb-2 text-base font-bold">Zusatzoptionen:</h2>
            {showMore.order.additionalOptions?.map((option) => (
              <span>
                {option.price.toLocaleString('de', { style: 'currency', currency: 'EUR' })} - {option.name}
              </span>
            ))}
          </div>
        )}
      </div>
      <div className="flex gap-3">
        <a href={createDisplayLink(getAdditionalDataValue('appImage'), false)} download target="_blank">
          <img src={createDisplayLink(getAdditionalDataValue('appImage'), false)} alt="app-logo" className="w-24 h-24 bg-center bg-cover rounded-md" />
        </a>
        <div className="flex flex-col items-center gap-3" style={{ cursor: 'copy' }} onClick={handleClick}>
          <div className="w-24 h-24 rounded-md" style={{ backgroundColor: getAdditionalDataValue('appTheme') }}></div>
          <h3>{getAdditionalDataValue('appTheme')}</h3>
        </div>
      </div>
    </div>
  );
}
