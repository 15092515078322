import { CircularProgress, Fab, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';
import { differenceInHours } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { createDisplayLink, formatUTCDate } from '../../utilities/helper';
import { ConditionalWrapper } from '../../components/ConditionalWrapper';
import { useParams } from 'react-router-dom';
import http from '../../utilities/http';
import { EventReport } from '../../utilities/interfaces';
import AdminLayout from '../../components/AdminLayout';

const ReportEvent: React.FC = () => {
  const { id: eventid } = useParams<{ id: string }>();
  const [genReports, setGenReports] = useState<EventReport[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    fetchReports();
    setIsLoading(false);
  }, []);

  async function fetchReports() {
    http
      .get(`/reporting/event/generate/${eventid}/getall`)
      .then(({ data }) => {
        setGenReports(data.data);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }

  async function genReport(to: string, preview: boolean) {
    setIsLoading(true);
    await http.get(`/reporting/event/generate/${eventid}?convertTo=${to}&preview=${preview}`);
    fetchReports();
    setIsLoading(false);
  }

  function getStatus(rep: EventReport) {
    if (rep?.datetime_finished_generation) return formatUTCDate(new Date(rep.datetime_finished_generation));

    if (rep?.error?.message) {
      return rep.error.message;
    }

    if (!rep?.reportFile) {
      if (differenceInHours(new Date(), new Date(rep.datetime_created)) > 2) return 'timeout';
      else return 'ongoing';
    }

    return '';
  }

  return (
    <AdminLayout>
      {isLoading && <CircularProgress />}
      {/* <JSONTreeComponent data={report} hideRoot/> */}
      <br />
      <a
        onClick={() => {
          genReport('pdf', true);
        }}
        className="cursor-pointer">
        Report Download PDF (free)
      </a>
      <br />
      <a
        onClick={() => {
          genReport('xlsx', false);
        }}
        className="cursor-pointer">
        Report Download XLSX (payed)
      </a>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Startzeit</TableCell>
              <TableCell>Endzeit/Status</TableCell>
              <TableCell align="center">Typ</TableCell>
              <TableCell align="center">TemplateId</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {genReports.map((rep) => (
              <TableRow key={rep._id}>
                <TableCell component="th" scope="row">
                  {formatUTCDate(new Date(rep.datetime_created))}
                </TableCell>
                <TableCell component="th" scope="row">
                  {getStatus(rep)}
                </TableCell>
                <TableCell align="center">{rep?.convertTo}</TableCell>
                <TableCell align="center">{rep?.templateId}</TableCell>
                <TableCell align="right">
                  <ConditionalWrapper
                    condition={!!rep.reportFile}
                    wrapper={(children) => <a href={`https://app.vystem.io/api/v1/file/display/${rep.reportFile}`}>{children}</a>}>
                    <Tooltip style={{ marginRight: '1rem' }} title="Report Download">
                      <Fab disabled={!rep.reportFile} size="small" color="secondary" aria-label="Download">
                        <CloudDownload />
                      </Fab>
                    </Tooltip>
                  </ConditionalWrapper>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </AdminLayout>
  );
};

export default ReportEvent;
