import { Button, Checkbox, Fab, FormControlLabel, Paper, Tab, Table, TableBody, TableCell, TableRow, Tabs, TextField, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AccessibilityNew, Add, Delete, Launch } from '@material-ui/icons';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import AdminLayout from '../../../components/AdminLayout';
import AsyncSearchField from '../../../components/asyncSearchField';
import http from '../../../utilities/http';
import { Booth, Organization, User } from '../../../utilities/interfaces';
import { SalesTab } from './SalesTab';

const useStyles = makeStyles(() => ({
  withCheckbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  paper: {
    width: '100%',
    padding: '1rem',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {},
  },
  submit: {
    marginTop: '1rem',
  },
  textCenter: {
    textAlign: 'center',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    minHeight: '70vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const NewOrganization = () => {
  const { id: organizationid } = useParams<{ id: string }>();
  const addUserInput = useRef<HTMLInputElement>(null);
  const addMembershipInput = useRef<HTMLInputElement>(null);
  const orgmembershipform = useRef<HTMLFormElement>(null);
  const { register, handleSubmit } = useForm();
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);
  const [orgsToMerge, setOrgsToMerge] = useState<Organization[]>([]);
  const [mergeSearchText, setMergeSearchText] = useState<string>('');

  const [activeTab, setActiveTab] = useState<'advanced' | 'general' | 'booths' | 'sales'>('general');

  const [msg, setMsg] = useState('');
  const [compaionKey, setCompaionKey] = useState<string>();
  const classes = useStyles();
  const router = useHistory();

  async function fetch() {
    http
      .get('/organization/get/' + organizationid)
      .then(({ data }) => {
        setOrganization(data.data);
      })
      .catch(() => {
        // router.push('/organizations');
      });
  }

  useEffect(() => {
    http
      .post('/organization/getall', { filter: mergeSearchText })
      .then(({ data }) => {
        // to avoid mergen with current org we filter current org out of the array
        setOrgsToMerge(data.data.filter((el: { _id: string }) => el._id != organizationid));
      })
      .catch(console.log);
  }, [mergeSearchText]);

  useEffect(() => {
    fetch();
  }, []);

  function handleAddUser() {
    const newUsers: User[] = organization?.users || [];
    if (newUsers && organization && addUserInput?.current?.value) {
      const newUser: User = {
        organization: organization,
        id: addUserInput?.current?.value,
        datetime_created: '',
        email: '',
        name: '',
        notificationToken: { web: '' },
        role: 'PARTICIPANT_ANONYM',
        _id: '',
        emailOptIn: false,
        orgmemberships: '',
        meetingassignments: '',
      };
      newUsers.push(newUser);
      setOrganization({ ...organization, users: newUsers });
    }
  }

  async function getCompanionKey() {
    http
      .post(`/auth/key/companion`, { orgid: organizationid })
      .then((data) => {
        const key = data?.data?.data?.access_token;
        if (key) setCompaionKey(key);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function handleDeleteMembership(id: string) {
    http
      .delete(`/organization/membership/${id}`, {})
      .then(() => {
        fetch();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function handleRoleChange(id: string, newRole: string) {
    http
      .post(`/user/orgmemberships/role`, { membershipid: id, role: newRole })
      .then(() => {
        fetch();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function handleAddMembership() {
    http
      .post('/organization/membership', {
        organization: organizationid,
        email: addMembershipInput?.current?.value,
        role: 'ORGANIZATION_SUBUSER',
      })
      .then(() => {
        fetch();
        orgmembershipform.current?.reset();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function combineOrgs() {
    http
      .post('/organization/combine', { mainOrg: organizationid, secondaryOrg: selectedOrg?._id })
      .then(() => {
        setSelectedOrg(null);
        setMergeSearchText('');
        fetch();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function submit(data: Record<string, string>) {
    if (organization) {
      const submitData = {
        organizationid: organization._id,
        userIds: organization.users.map((user) => user._id),
        ...data,
      };
      http
        .post('/organization/update', submitData)
        .then(() => {
          setMsg('Success');
        })
        .catch(() => {
          setMsg('Server error!');
        });
    }
  }

  return (
    <AdminLayout>
      <Tabs value={activeTab} onChange={(e, value) => setActiveTab(value)} aria-label="basic tabs example">
        <Tab label="General" value="general" />
        <Tab label="Sales" value="sales" />
        <Tab label="Booths" value="booths" />
        <Tab label="Advanced" value="advanced" />
      </Tabs>
      <div>
        {activeTab === 'advanced' && (
          <>
            <Paper className={classes.paper}>
              <p>
                Eine andere Organization mit dieser Organization zusammenführen. Diese Organization wird damit Hauptorganization und die ausgewählte
                Organization aufgelöst.
              </p>
              <AsyncSearchField
                label="Choose Secondary Org"
                onOptionSelected={(org) => setSelectedOrg(org)}
                onChange={(searchText) => setMergeSearchText(searchText)}
                searchText={mergeSearchText}
                options={orgsToMerge}
              />
              <Button className={classes.submit} onClick={() => combineOrgs()} disabled={selectedOrg === null} fullWidth variant="contained" color="secondary">
                Merge Organization
              </Button>
            </Paper>
            <Button variant="outlined" onClick={getCompanionKey}>
              Companion Schlüssel generieren
            </Button>
            {compaionKey && <div style={{ wordWrap: 'break-word' }}>Comapion Key: {compaionKey}</div>}
          </>
        )}

        {activeTab === 'general' && (
          <form onSubmit={handleSubmit(submit)} className={classes.wrapper}>
            <Paper className={classes.paper}>
              <h1 className={classes.textCenter}>Update Organization</h1>
              {organization?.name && (
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Name"
                  fullWidth
                  color="secondary"
                  {...register('name')}
                  defaultValue={organization?.name}
                />
              )}

              <div>
                <h3>Users:</h3>
                <Table>
                  <TableBody>
                    {organization?.users.map((user) => (
                      <TableRow key={user._id}>
                        <TableCell component="th" scope="row">
                          {user._id}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {user?.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {user?.email}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {user?.role}
                        </TableCell>
                        <TableCell component="th" scope="row" />
                        <TableCell align="right" />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <div className={classes.withCheckbox}>
                  <TextField variant="outlined" margin="normal" label="User ID" fullWidth name="name" color="secondary" inputRef={addUserInput} />
                  <div style={{ margin: '0 1rem' }}>
                    <Tooltip onClick={() => handleAddUser()} title="Add">
                      <Fab size="small" color="secondary" aria-label="add">
                        <Add />
                      </Fab>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div>
                <h3>Orgmembers:</h3>
                <Table>
                  <TableBody>
                    {organization?.memberships.map((membership) => (
                      <TableRow key={membership._id}>
                        <TableCell component="th" scope="row">
                          {membership._id}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {membership?.user?.name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {membership?.user?.email}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {membership?.role}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {membership.organization === membership?.user?.organization ? 'Active' : 'Not active'}
                        </TableCell>
                        <TableCell align="right">
                          <Tooltip
                            onClick={() =>
                              handleRoleChange(membership._id, membership?.role == 'ORGANIZATION_SUBUSER' ? 'ORGANIZATION_ADMIN' : 'ORGANIZATION_SUBUSER')
                            }
                            title="Change Role">
                            <Fab size="small" color="secondary" aria-label="add">
                              <AccessibilityNew />
                            </Fab>
                          </Tooltip>
                          <Tooltip onClick={() => handleDeleteMembership(membership._id)} title="Delete">
                            <Fab size="small" color="secondary" aria-label="add">
                              <Delete />
                            </Fab>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <form ref={orgmembershipform} className="flex justify-center w-full space-between">
                  <div className={classes.withCheckbox}>
                    <TextField fullWidth variant="outlined" margin="normal" label="E-Mail" name="name" color="secondary" inputRef={addMembershipInput} />
                    <div style={{ margin: '0 1rem' }}>
                      <Tooltip onClick={() => handleAddMembership()} title="Add">
                        <Fab size="small" color="secondary" aria-label="add">
                          <Add />
                        </Fab>
                      </Tooltip>
                    </div>
                  </div>
                </form>
              </div>
              <div className="flex flex-col">
                <h3>Subscription:</h3>
                {organization && (
                  <>
                    <FormControlLabel
                      aria-label="Acknowledge"
                      control={<Checkbox defaultChecked={organization?.isFreeTier} {...register('isFreeTier')} />}
                      label="Is Free Tier"
                    />
                  </>
                )}
                <h3>Platform permissions:</h3>
                {organization?.platformPermissions?.addSubUsersToExhibitors !== undefined && (
                  <FormControlLabel
                    aria-label="Acknowledge"
                    control={
                      <Checkbox
                        defaultChecked={organization?.platformPermissions?.addSubUsersToExhibitors}
                        {...register('platformPermissions.addSubUsersToExhibitors')}
                      />
                    }
                    label="Add sub users to exhibtors"
                  />
                )}
                {organization?.platformPermissions?.resetSubUsersPassword !== undefined && (
                  <FormControlLabel
                    aria-label="Acknowledge"
                    control={
                      <Checkbox
                        defaultChecked={organization?.platformPermissions?.resetSubUsersPassword}
                        {...register('platformPermissions.resetSubUsersPassword')}
                      />
                    }
                    label="Reset sub users password"
                  />
                )}

                {organization && (
                  <FormControlLabel
                    aria-label="Acknowledge"
                    control={<Checkbox defaultChecked={organization?.allowExceedingBudget} {...register('allowExceedingBudget')} />}
                    label=" Post-Paid: Erlaube Überziehen von Livestream- und Videokonferenz-Budget"
                  />
                )}
              </div>
              <Button className={classes.submit} type="submit" fullWidth variant="contained" color="secondary">
                Update Organization
              </Button>
              <p className={classes.textCenter}>{msg}</p>
            </Paper>
          </form>
        )}

        {activeTab === 'sales' && <SalesTab />}
        {activeTab === 'booths' && (
          <Paper className={classes.paper}>
            <h3>Stände:</h3>
            <Table>
              <TableBody>
                {organization?.booths.map((booth: Booth) => (
                  <TableRow key={booth._id}>
                    <TableCell component="th" scope="row">
                      {booth._id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {booth?.name}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {booth?.event?.name}
                    </TableCell>
                    <TableCell align="right">
                      <a href={`https://app.vystem.io/event/${booth?.event?.slug}/live`}>
                        <Tooltip style={{ marginRight: '1rem' }} title="Open">
                          <Fab size="small" color="secondary" aria-label="add">
                            <Launch />
                          </Fab>
                        </Tooltip>
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )}
      </div>
    </AdminLayout>
  );
};

export default NewOrganization;
