import http from '../../../utilities/http';
import { Promotion } from '../../../utilities/interfaces';
import { createDisplayLink } from '../../../utilities/helper';
import { Alert, Button, TextField } from '@mui/material';
import { locale } from '../../../utilities/interfaces';
import { useState } from 'react';
interface AdBannerProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  promotion: Promotion;
  onFetch: () => void;
}

export const AdBannerEntry = ({
  header,
  locale,
  fileId,
  imageUrl,
  onFetch,
}: {
  header: string;
  locale: locale;
  fileId: string;
  imageUrl?: string;
  onFetch: () => void;
}) => {
  const [url, setUrl] = useState<string | undefined>(imageUrl);

  async function submit({ fileId, url }: { fileId?: string; url?: string }) {
    try {
      await http.post('/config/updatead', { fileId, locale, url });
    } catch (err) {
      console.error(err);
    }
  }

  async function uploadImage(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      const formData = new FormData();
      formData.append('file0', e.target.files[0]);
      const { data } = await http.post('/file/upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      await submit({ fileId: data.data.fileId });
      onFetch();
    }
  }

  return (
    <div className="mb-12">
      <p className="text-lg"> {header}</p>
      <img src={createDisplayLink(fileId)} />
      <label id="fileuploadselect" className="flex justify-center border-2 my-4 rounded cursor-pointer">
        <p className="text-lg">Bild hochladen</p>
        <input className="hidden" id="fileuploadselect" onChange={(e) => uploadImage(e)} accept={'image/jpeg,image/jpg,image/gif,image/png'} type="file" />
      </label>
      <div className="flex">
        <TextField label="Optional link" className="w-full mr-4" onChange={(e) => setUrl(e.target.value)} defaultValue={imageUrl} />
        <Button onClick={() => submit({ url })}>Speichern</Button>
      </div>
    </div>
  );
  // );
};
