import React from 'react';
import EventTemplate from '../../components/EventTemplate';
import AdminLayout from '../../components/AdminLayout';

const EventTemplatesIndex: React.FC = () => {
  return (
    <AdminLayout>
      <EventTemplate />
    </AdminLayout>
  );
};

export default EventTemplatesIndex;
