import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog } from '@material-ui/core';
import React from 'react';
import { ConditionalWrapper } from './ConditionalWrapper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  closeButton: {
    width: '30px',
    height: '30px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    color: 'white',
    zIndex: 2,
    opacity: 0.7,
    background: 'gray',
    cursor: 'pointer',
    margin: '1.6rem',
    transition: 'all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1)',
  },
  modal: {
    cursor: 'default',
    padding: `2rem`,
    maxWidth: '550px',
    animation: 'fadeIn 200ms',
    animationFillMode: 'forwards',
    backgroundColor: 'white',
    maxHeight: '80vh',
    overflow: 'auto',
  },
  title: {
    margin: 0,
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
}));

interface VyModalProps {
  children: (onClose: () => void, onSubmit?: () => void) => JSX.Element;
  onClose: () => void;
  onSubmit?: () => void;
  title?: string;
  open?: boolean;
  displayCloseButton?: boolean;
  hasDefaultPadding?: boolean; // whether the modal has a padding by default
  maxWidth?: string | number;
  disableDefaultStyling?: boolean;
  disableEnforceFocus?: boolean;
  maxHeight?: string;
}

export default function VyModal({
  children,
  onClose,
  onSubmit,
  title = '',
  displayCloseButton = false,
  open = false,
  hasDefaultPadding = true,
  maxWidth = '550px',
  disableDefaultStyling = false,
  disableEnforceFocus = false,
  maxHeight = '80vh',
}: VyModalProps) {
  const styles = useStyles();
  // useEffect(() => {
  //   if (open) {
  //     windowOffset = window.scrollY;
  //     document.body.setAttribute('style', `position: fixed; left: 0; right: 0; top: -${windowOffset}px`);
  //   } else {
  //     document.body.setAttribute('style', '');
  //     window.scrollTo(0, windowOffset);
  //   }
  // }, [open]);
  if (!open) return <></>;
  return (
    <Dialog
      style={{ borderRadius: 0, width: '100vw' }}
      disableEnforceFocus={disableEnforceFocus}
      maxWidth="xl"
      open={true}
      onClick={(e) => e.stopPropagation()}
      onClose={() => onClose?.()}>
      {/* this input needs to be here. */}
      <input className="hidden" />
      <div className={styles.modal} style={{}}>
        <ConditionalWrapper
          condition={!!onSubmit}
          wrapper={(c) => (
            <form onClick={(e) => e.stopPropagation} onSubmit={onSubmit}>
              {c}
            </form>
          )}>
          <>
            {title ? <h1 className={styles.title}>{title}</h1> : <></>}
            {displayCloseButton && (
              <div onClick={() => onClose?.()} className={styles.closeButton}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            )}
            {children(onClose, onSubmit)}
          </>
        </ConditionalWrapper>
      </div>
    </Dialog>
  );
}
