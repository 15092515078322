import React, { useEffect, useState } from 'react';
import { deleteInvoiceService, generateInvoiceService, getAllTicketOrders, payOrderManually } from '../../utilities/servicesNew';
import { SupportedTicketPaymentCurrencies, TicketOrder } from '../../utilities/interfaces';
import {
  CircularProgress,
  Fab,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { Search, CreditCard, Edit, Receipt } from '@material-ui/icons';
import { VyConfirm } from '../VyConfirm';
import { EditOrderModal } from './EditOrderModal';

const TicketOrders: React.FC = () => {
  const [orders, setOrders] = useState<TicketOrder[]>([]);
  const [filteredOrders, setFilteredOrders] = useState<TicketOrder[]>([]);
  const [isLoading, setIsloading] = useState(false);
  const [editOrder, setEditOrder] = useState<TicketOrder>();

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    if (editOrder) {
      setEditOrder(orders.find((o) => o._id === editOrder._id));
    }
  }, [editOrder, orders]);

  async function fetchOrders(callback?: () => void) {
    setIsloading(true);
    try {
      const _orders = await getAllTicketOrders();
      setFilteredOrders(_orders);
      setOrders(_orders);
    } catch (err) {
      console.error(err);
    }
    setIsloading(false);
    callback?.();
  }

  async function deleteInvoice(orderId: string) {
    setIsloading(true);
    try {
      await deleteInvoiceService(orderId);
    } catch (err) {
      console.error(err);
    }
    await fetchOrders();
  }

  async function generateInvoice(orderId: string, sendInvoiceViaMail: boolean) {
    setIsloading(true);
    try {
      await generateInvoiceService(orderId, sendInvoiceViaMail);
    } catch (err) {
      console.error(err);
    }
    await fetchOrders();
  }

  function handleSearch({ target }: React.ChangeEvent<HTMLInputElement>) {
    if (!target.value || !orders) {
      setFilteredOrders(orders);
      return;
    }
    const _filteredOrders = orders.filter((order) => {
      const condition =
        Object.values(order.billingData || {}).some((prop) => typeof prop === 'string' && new RegExp(target.value, 'i').test(prop)) ||
        (order.orderNumber && new RegExp(target.value, 'i').test(order.orderNumber)) ||
        (order.invoiceNumber && new RegExp(target.value, 'i').test(order.invoiceNumber.toString()));
      return condition;
    });
    setFilteredOrders(_filteredOrders);
  }

  function formatPrice(price: number, currency: SupportedTicketPaymentCurrencies) {
    return price?.toLocaleString('de', { style: 'currency', currency: currency || 'EUR' });
  }

  function formatOrderPrice(order: TicketOrder) {
    return formatPrice(parseFloat(order.payment?.amount?.value || '0'), order.payment?.amount?.currency || 'EUR');
  }

  function formatOrderStatus(order: TicketOrder) {
    if (order.datetime_deleted && order.creditNote) return `Deleted & Credit note}`;
    if (order.creditNote) return 'Credit note created';
    if (order.datetime_deleted) return 'Deleted';
    if (order.datetime_cancelled) return 'Cancelled';

    // Better formatting of payment status (eg: paid_manually -> Paid manually)
    const status = `${order.payment?.status || ''}`.replace('_', ' ');
    return status?.charAt(0).toUpperCase() + status?.slice(1);
  }

  async function _payOrderManually(orderId: string) {
    setIsloading(true);
    try {
      await payOrderManually(orderId);
      fetchOrders(() => setIsloading(false));
    } catch (err) {
      console.error(err);
      setIsloading(false);
    }
  }

  return (
    <>
      <EditOrderModal
        editOrder={editOrder}
        onClose={() => setEditOrder(undefined)}
        isLoading={isLoading}
        onDeleteInvoice={deleteInvoice}
        onUpdate={fetchOrders}
        onGenerateInvoice={generateInvoice}
      />
      <div className="w-full">
        <div className="flex items-center mb-4">
          <Paper className="w-full flex p-4">
            <TextField
              className="w-full"
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Paper>
        </div>
        {filteredOrders?.length === 0 && isLoading && <CircularProgress />}
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Order number</TableCell>
                <TableCell>Invoice number</TableCell>
                <TableCell>Sum</TableCell>
                <TableCell>Event Id</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Order Date</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredOrders?.slice(0, 100).map((order) => (
                <TableRow key={order._id}>
                  <TableCell component="th" scope="row">
                    {order.orderNumber}
                  </TableCell>
                  <TableCell>{order.invoiceNumber}</TableCell>
                  <TableCell>{formatOrderPrice(order)}</TableCell>
                  <TableCell>{order.event}</TableCell>
                  <TableCell>{order.billingData?.email}</TableCell>
                  <TableCell>{formatOrderStatus(order)}</TableCell>
                  <TableCell>{new Date(order.datetime_created).toLocaleString()}</TableCell>
                  <TableCell>
                    <Tooltip title="Edit billingdata" placement="top">
                      <Fab size="small" color="secondary" aria-label="add" onClick={() => setEditOrder(order)}>
                        <Edit />
                      </Fab>
                    </Tooltip>
                    {order?.invoice && (
                      <Tooltip title="Open invoice" placement="top">
                        <Fab size="small" color="secondary" aria-label="add" onClick={() => window.open(`https://static.vystem.io/${order.invoice}`)}>
                          <Receipt />
                        </Fab>
                      </Tooltip>
                    )}
                    {!['paid', 'paid_manually'].includes(order.payment?.status || '') && (
                      <>
                        {isLoading ? (
                          <CircularProgress />
                        ) : (
                          <VyConfirm onConfirm={() => _payOrderManually(order._id)}>
                            <Tooltip title="Pay manually" placement="top">
                              <Fab size="small" color="secondary" aria-label="add">
                                <CreditCard />
                              </Fab>
                            </Tooltip>
                          </VyConfirm>
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default TicketOrders;
