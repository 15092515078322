import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { EventTemplate } from '../../utilities/interfaces';
import { createDisplayLink } from '../../utilities/helper';

interface EventTemplateProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  eventTemplate: EventTemplate;
}

const TemplateCard: React.FC<EventTemplateProps> = ({ eventTemplate, className, onClick }) => {
  return (
    <Card onClick={onClick} className={`relative cursor-pointer ${className}`}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt={eventTemplate.name || 'event template'}
          style={{ maxHeight: '150px' }}
          image={createDisplayLink(eventTemplate?.previewImage)}
          title={eventTemplate.name || 'event template'}
        />
        <CardContent>
          <Typography gutterBottom variant="body1" className="font-bold" component="h4">
            {eventTemplate.name}
          </Typography>
          <Typography variant="body2" className="text-xl break-all font-bold" component="p">
            {eventTemplate.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default TemplateCard;
