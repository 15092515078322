import {
  CircularProgress,
  Fab,
  FormControlLabel,
  FormGroup,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import DetailModal from './detailmodal';
import { LiveUsersInterface } from '../../utilities/interfaces';
import http from '../../utilities/http';
import AdminLayout from '../../components/AdminLayout';

type Timer = ReturnType<typeof setTimeout>;

const UserActivityPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [autorefresh, setAutoRefresh] = useState<boolean>(false);
  const [refreshInterval, setRefreshInterval] = useState<Timer | null>();
  const [data, setData] = useState<LiveUsersInterface[]>([]);
  const [modal, setModal] = useState<null | LiveUsersInterface>(null);

  async function refresh() {
    if (isRefreshing) return;
    try {
      setIsRefreshing(true);
      const data = await http.get('/reporting/live/users', {});
      console.log(data);
      setData(data.data.data);
    } catch (err) {
      console.error(err);
    }
    setIsRefreshing(false);
  }

  function reloadAutoRefresh() {
    if (autorefresh) {
      setRefreshInterval(setInterval(() => refresh(), 10000));
    } else {
      if (refreshInterval) clearInterval(refreshInterval);
      setRefreshInterval(null);
    }
  }

  useEffect(() => {
    reloadAutoRefresh();
  }, [autorefresh]);

  async function fetch() {
    setIsLoading(true);
    await refresh();
    setIsLoading(false);
  }

  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <DetailModal onClose={() => setModal(null)} user={modal} onChange={() => refresh()} />
      <AdminLayout>
        {isLoading && <CircularProgress />}
        {!isLoading && (
          <>
            <FormGroup row>
              <FormControlLabel control={<Switch checked={autorefresh} onChange={() => setAutoRefresh(!autorefresh)} name="checkedA" />} label="Autorefresh" />
            </FormGroup>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Userid</TableCell>
                    <TableCell>Username</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Frontendtabs</TableCell>
                    <TableCell>Videotabs</TableCell>
                    <TableCell>Meetingroom</TableCell>
                    <TableCell>Attendeeid</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.map((item) => (
                    <TableRow key={item.id}>
                      <TableCell component="th" scope="row">
                        {item.user?._id}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.user?.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.user?.email}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.browsertabcount?.frontendcount}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.browsertabcount?.videosoftwarecount}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.attendee?.meetingid}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {item.attendeeid}
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip style={{ marginRight: '1rem' }} title="Rename">
                          <Fab size="small" color="secondary" aria-label="add" onClick={() => setModal(item)}>
                            <Edit />
                          </Fab>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </AdminLayout>
    </>
  );
};

export default UserActivityPage;
