import { Button, Paper, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import http from '../../utilities/http';
import AdminLayout from '../../components/AdminLayout';

const useStyles = makeStyles((theme) => ({
  withCheckbox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paper: {
    maxWidth: '500px',
    width: '100%',
    padding: '1rem',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {},
  },
  submit: {
    marginTop: '1rem',
  },
  textCenter: {
    textAlign: 'center',
  },
  wrapper: {
    width: '100%',
    display: 'flex',
    minHeight: '70vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const NewOrganization = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [resMsg, setResMsg] = useState('');
  const classes = useStyles();

  function createEvent(data: Record<string, string>) {
    http
      .post('/organization/create', data)
      .then(() => {
        setResMsg('Success!');
      })
      .catch((err) => {
        setResMsg('Server error!');
        console.log(err);
      });
  }

  return (
    <AdminLayout>
      <div className={classes.wrapper}>
        <Paper className={classes.paper}>
          <form className={classes.form} onSubmit={handleSubmit(createEvent)} noValidate>
            <h1 className={classes.textCenter}>New Organization</h1>
            <TextField variant="outlined" margin="normal" label="Name" name="name" color="secondary" inputRef={register} />
            <Button className={classes.submit} type="submit" fullWidth variant="contained" color="secondary">
              Create Organization
            </Button>
            <p className={classes.textCenter}>{resMsg}</p>
          </form>
        </Paper>
      </div>
    </AdminLayout>
  );
};

export default NewOrganization;
