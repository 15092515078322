import http from '../../../utilities/http';
import { Promotion } from '../../../utilities/interfaces';
import { createDisplayLink } from '../../../utilities/helper';
import { Alert, TextField } from '@mui/material';
import { locale } from '../../../utilities/interfaces';
import { AdBannerEntry } from './AdBannerEntry';
interface AdBannerProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  promotion: Promotion;
  onFetch: () => void;
}

export const AdBanner: React.FC<AdBannerProps> = (props) => {
  const { fileIds, urls } = props.promotion;

  return (
    <div className="flex flex-col justify-center">
      <Alert severity="info" className="mb-8">
        Aspect Ratio ist 10:1
      </Alert>

      <AdBannerEntry header="Bild für deutsche Promotion" locale="de" fileId={fileIds.de} onFetch={props.onFetch} imageUrl={urls.de} />
      <AdBannerEntry header="Bild für englische Promotion" locale="en" fileId={fileIds.en} onFetch={props.onFetch} imageUrl={urls.en} />
      <AdBannerEntry header="Bild für französische Promotion" locale="fr" fileId={fileIds.fr} onFetch={props.onFetch} imageUrl={urls.fr} />
    </div>
  );
};
