import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import { AxiosError, AxiosRequestConfig } from 'axios';
import http from './http';
import { EventInterface, EventTemplate } from './interfaces';

type AxiosBaseReturn = BaseQueryFn<
  {
    url: string;
    method: AxiosRequestConfig['method'];
    data?: AxiosRequestConfig['data'];
  },
  unknown,
  unknown
>;

const axiosBaseQuery = (): AxiosBaseReturn => async ({ url, method, data }) => {
  try {
    const result = await http({ url, method, data });
    return { data: result.data.data };
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    return {
      error: { status: err.response?.status, data: err.response?.data },
    };
  }
};

export const defaultApi = createApi({
  reducerPath: 'defaultApi',
  baseQuery: axiosBaseQuery(),
  endpoints: (build) => ({
    getAllEventTemplates: build.query<EventTemplate[], void>({
      query: () => ({
        url: '/eventtemplate',
        method: 'GET',
      }),
    }),
    getAllEvents: build.query<EventInterface[], GetAllEventsProps>({
      query: (data) => ({
        url: '/event/getall',
        method: 'POST',
        data,
      }),
    }),
  }),
});

interface GetAllEventsProps {
  onlyFuture: boolean;
  limit: number;
  population: string[];
  includePrivate: boolean;
  project: Record<string, string | number>;
}

export const { useGetAllEventTemplatesQuery, useGetAllEventsQuery } = defaultApi;
