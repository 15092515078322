import { Button, CircularProgress, FormControl, FormControlLabel, FormLabel, LinearProgress, Radio, RadioGroup, TextField } from '@material-ui/core';
import { Alert } from '@mui/lab';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { OrgUsageSumamry, PlatformUsageBudget, createBudgetService, deleteBudgetService, fetchOrgUsageSummary } from './service';
import { UsageProgressBar } from './UsageProgressBar';

export const SalesTab: React.FC = () => {
  const { id: organizationid } = useParams<{ id: string }>();

  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting },
    reset,
  } = useForm<Partial<PlatformUsageBudget>>();

  const [isLoading, setIsLoading] = useState(false);
  const [summary, setSummary] = useState<OrgUsageSumamry>();

  async function fetchData() {
    setIsLoading(true);
    try {
      const summary = await fetchOrgUsageSummary(organizationid);
      setSummary(summary);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  }

  async function createBudget(values: Partial<PlatformUsageBudget>) {
    try {
      await createBudgetService({
        ...values,
        organization: organizationid,
      });
      fetchData();
    } catch (err) {
      console.error(err);
    }
    reset();
  }

  async function deleteBudget(budgetId: string) {
    try {
      await deleteBudgetService(budgetId);
      fetchData();
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    reset();
    fetchData();
  }, []);

  return (
    <div className="flex flex-col space-y-6">
      {isLoading && <CircularProgress />}
      {summary && (
        <div className="flex flex-col space-y-4">
          {summary.hasUsageAlert && <Alert severity="error">Organization is overusing budgets!</Alert>}

          <b>Usage</b>
          <UsageProgressBar objectKey="maxParallelUsers" summary={summary} postFix="users" title="Max parallel users" />
          <UsageProgressBar objectKey="video" summary={summary} postFix="min" title="Video" />
          <UsageProgressBar objectKey="stream" summary={summary} postFix="min" title="Stream" />
          <UsageProgressBar objectKey="admins" summary={summary} postFix="" title="Admins" />
          <UsageProgressBar objectKey="subusers" summary={summary} postFix="" title="Subusers" />
          <UsageProgressBar objectKey="emailsPerMonth" summary={summary} postFix="mails" title="E-Mails pro Monat" />
        </div>
      )}
      <b>Budgets</b>
      {!isLoading && (!summary || summary.allBudgets.length === 0) && <>No data</>}
      {summary?.allBudgets?.map((budget, idx) => (
        <div key={`budget-${idx}-${budget.value}-${budget.type}`} className="rounded bg-gray-50 border flex flex-row justify-between p-4">
          <div>
            <p className="text-sm text-gray-500">Type: {budget.type}</p>
            <p>{budget.description}</p>
            <p className="font-mono">
              {budget.value}
              {['stream', 'video'].includes(budget.type) && ' min'}
            </p>
          </div>
          <div>
            {budget._id && (
              <Button variant="outlined" onClick={() => deleteBudget(budget._id!)}>
                Delete
              </Button>
            )}
          </div>
        </div>
      ))}
      <hr />
      <form onSubmit={handleSubmit(createBudget)} className="flex flex-col">
        <div className="font-bold">Add new Budget</div>
        <Controller
          control={control}
          name="type"
          defaultValue="video"
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <FormControl>
              <FormLabel id="demo-radio-buttons-group-label">Type</FormLabel>
              <RadioGroup onChange={(e, value) => onChange(value)} value={value} aria-labelledby="demo-radio-buttons-group-label" name="radio-buttons-group">
                <FormControlLabel value="video" control={<Radio />} label="Videominutes" />
                <FormControlLabel value="stream" control={<Radio />} label="Streamminutes" />
                <FormControlLabel value="admins" control={<Radio />} label="Admin" />
                <FormControlLabel value="subusers" control={<Radio />} label="Subusers" />
                <FormControlLabel value="emailsPerMonth" control={<Radio />} label="Mails per month" />
                <FormControlLabel value="maxParallelUsers" control={<Radio />} label="Max parallel users" />
              </RadioGroup>
            </FormControl>
          )}
        />
        <TextField disabled={isSubmitting} {...register('description')} label="Description" />
        <TextField required disabled={isSubmitting} type="number" label="Value" {...register('value', { valueAsNumber: true, required: true })} />
        <Button type="submit" color="primary" disabled={isSubmitting}>
          Save
        </Button>
      </form>
    </div>
  );
};
