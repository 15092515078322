import { useEffect, useState } from 'react';
import AdminLayout from '../../components/AdminLayout';
import { getPremiumServiceOrders, togglePremiumFeature, updatePremiumServiceOrderStatus } from '../../utilities/servicesNew';
import { OrderStatus, PremiumServiceOrder, PremiumServiceType } from '../../utilities/interfaces';
import { Select } from '@mui/material';
import { IconButton, MenuItem } from '@material-ui/core';
import { useToasts } from 'react-toast-notifications';
import VyTooltip from '../../components/VyTooltip';
import OrderDetailsModal from '../../components/premiumorders/OrderDetailsModal';
import { PowerSettingsNew, Visibility } from '@material-ui/icons';

const canBeActivated: PremiumServiceType[] = [PremiumServiceType.ProAnalytics, PremiumServiceType.Seo];
const thStyle = 'px-3 py-2';
const tdStyle = 'px-3 py-4';

const PremiumOrders = () => {
  const [orders, setOrders] = useState<PremiumServiceOrder[]>();
  const [showMore, setShowMore] = useState<{ modal: boolean; order?: PremiumServiceOrder }>({ modal: false });
  const { addToast } = useToasts();

  async function fetchOrders() {
    try {
      const data = await getPremiumServiceOrders();
      setOrders(data);
    } catch (error) {
      console.error(error);
    }
  }

  function getDate(date: string) {
    return new Date(date).toLocaleString();
  }

  async function handleStatusChange(orderId: string, status: PremiumServiceOrder['status']) {
    try {
      await updatePremiumServiceOrderStatus(orderId, { status: status });
      addToast('Status erfolgreich aktualisiert', { appearance: 'success' });
      fetchOrders();
    } catch (error) {
      addToast('Fehler beim Aktualisieren des Status.', { appearance: 'error' });
    }
  }

  function hasMoreDetails(order: PremiumServiceOrder) {
    return order.service === PremiumServiceType.CustomDomain || order.service === PremiumServiceType.CustomAppstoreApp;
  }

  function handleShowMore(order: PremiumServiceOrder) {
    setShowMore({ modal: true, order: order });
  }

  async function handleToggleFeature(order: PremiumServiceOrder) {
    try {
      await togglePremiumFeature(order.event._id, order._id, order.service, order.featureIsActive!);
      addToast(`Feature erfolgreich ${order.featureIsActive ? 'deaktiviert' : 'aktiviert'}`, { appearance: 'success' });
      fetchOrders();
    } catch (error) {
      addToast(`Fehler beim ${order.featureIsActive ? 'Deaktiveren' : 'Aktivieren'} des Features`, { appearance: 'error' });
    }
  }

  useEffect(() => {
    fetchOrders();
  }, []);

  return (
    <AdminLayout>
      {orders && (
        <div className="overflow-auto border rounded-md shadow-md">
          <table className="w-full">
            <thead>
              <tr className="text-left text-gray-600 bg-gray-100">
                <th className={thStyle}>User</th>
                <th className={thStyle}>Org</th>
                <th className={thStyle}>E-Mail</th>
                <th className={thStyle}>Event</th>
                <th className={thStyle}>Service</th>
                <th className={thStyle}>Total</th>
                <th className={thStyle}>Status</th>
                <th className={thStyle}>Order Date</th>
                <th className={thStyle}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order._id} className="relative transition-colors border-b hover:translate-x-3">
                  <td className={tdStyle}>{order.user.name}</td>
                  <td className={tdStyle}>{order.organization.name}</td>
                  <td className={tdStyle}>{order.user.email}</td>
                  <td className={tdStyle}>{order.event.name}</td>
                  <td className={tdStyle}>{order.service}</td>
                  <td className={tdStyle}>{order.total.toLocaleString('de', { style: 'currency', currency: 'EUR' })}</td>
                  <td className={tdStyle}>
                    <Select id="status" value={order.status} onChange={(e) => handleStatusChange(order._id, e.target.value as OrderStatus)} className="w-full">
                      {Object.values(OrderStatus).map((option) => (
                        <MenuItem key={option} value={option} disabled={option === OrderStatus.Cancelled}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </td>
                  <td className={tdStyle}>{getDate(order.datetime_created)}</td>
                  <td className={tdStyle}>
                    {canBeActivated.includes(order.service) && (
                      <VyTooltip title={order.featureIsActive ? 'Deaktivieren' : 'Aktivieren'}>
                        <IconButton onClick={() => handleToggleFeature(order)}>
                          {order.featureIsActive ? <PowerSettingsNew color="primary" /> : <PowerSettingsNew color="error" />}
                        </IconButton>
                      </VyTooltip>
                    )}
                    {hasMoreDetails(order) && (
                      <VyTooltip title="Mehr anzeigen">
                        <IconButton onClick={() => handleShowMore(order)}>
                          <Visibility />
                        </IconButton>
                      </VyTooltip>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {showMore.modal && <OrderDetailsModal setShowMore={setShowMore} showMore={showMore} />}
    </AdminLayout>
  );
};

export default PremiumOrders;
