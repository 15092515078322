import { Button, FormControlLabel, FormGroup, Switch, TextField, TextFieldProps } from '@material-ui/core';
import { HTMLInputTypeAttribute, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TicketOrder } from '../../utilities/interfaces';
import VyModal from '../VyModal';
import { updateBillingDataService } from '../../utilities/servicesNew';

export const EditOrderModal: React.FC<{
  editOrder?: TicketOrder;
  onClose: () => void;
  isLoading: boolean;
  onDeleteInvoice: (orderId: string) => void;
  onUpdate: () => void;
  onGenerateInvoice: (orderId: string, sendInvoiceToCustomer: boolean) => void;
}> = ({ editOrder, onClose, isLoading, onDeleteInvoice, onUpdate, onGenerateInvoice }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm();

  const [isUpdating, setIsUpdating] = useState(false);
  const [sendInvoiceToCustomer, setSendInvoiceToCustomer] = useState(true);
  const loading = isLoading || isUpdating;

  const formDisabled = !!editOrder?.invoice || loading;

  useEffect(() => {
    if (editOrder) {
      reset(editOrder.billingData);
    }
  }, [editOrder]);

  function input(name: string, type: HTMLInputTypeAttribute = 'text', validate?: (value: string) => string, required = true, params?: TextFieldProps) {
    return (
      <TextField
        {...params}
        error={!!errors[name]}
        type={type}
        disabled={formDisabled}
        className="w-full"
        required={required}
        variant="outlined"
        {...register(name, required ? { required: 'Field is required!' } : { required: false })}
        name={name}
        label={name}
      />
    );
  }

  async function submit(data: TicketOrder) {
    console.log(data);
    setIsUpdating(true);
    try {
      await updateBillingDataService(editOrder!._id, data);
    } catch (err) {
      console.error(err);
    }
    setIsUpdating(false);
    onUpdate();
  }

  return (
    <VyModal open={!!editOrder} onClose={onClose}>
      {(onClose) => (
        <div className="flex flex-col space-y-2">
          {editOrder?.invoice && (
            <div className="bg-blue-800 p-2 rounded text-white flex flex-col space-y-2">
              <p className="font-bold">Invoice currently exists!</p>
              <p>Invoice has to be deleted before changes to invoice can be made</p>
              <Button color="primary" variant="contained" disabled={isLoading} onClick={() => onDeleteInvoice(editOrder!._id)}>
                Delete invoice
              </Button>
            </div>
          )}
          <form className="w-full flex flex-col space-y-5" onSubmit={handleSubmit(submit)}>
            {input('company', undefined, undefined, false)}
            <div className="flex flex-row w-full space-x-5">
              {input('firstname')}
              {input('lastname')}
            </div>

            {input('street')}
            {input('postcode')}
            {input('city')}
            {input('country')}

            {input('vat', undefined, undefined, false)}
            {input('comment', 'text', undefined, false, { multiline: true, rows: 3 })}
            <Button variant="outlined" disabled={formDisabled} type="submit" color="primary">
              Save
            </Button>

            {!editOrder?.invoice && !isDirty && (
              <div className="bg-blue-800 p-2 rounded text-white flex flex-col space-y-2">
                <p className="font-bold">Generate invoice for order</p>
                <p>
                  The invoice is simply generated and <u>not</u> send to the customer
                </p>
                <FormGroup>
                  <FormControlLabel
                    control={<Switch checked={sendInvoiceToCustomer} onChange={() => setSendInvoiceToCustomer(!sendInvoiceToCustomer)} />}
                    label="Send invoice to customer vie email"
                  />
                </FormGroup>
                <Button color="primary" variant="contained" disabled={isLoading} onClick={() => onGenerateInvoice(editOrder!._id, sendInvoiceToCustomer)}>
                  Generate new invoice
                </Button>
              </div>
            )}
          </form>
        </div>
      )}
    </VyModal>
  );
};
