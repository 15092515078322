import React from 'react';
import { useGetAllEventTemplatesQuery } from '../../utilities/rtkquery';
import NewEventTemplateForm from './NewEventTemplateForm';
import TemplateList from './TemplateList';

const EventTemplate: React.FC = () => {
  const { data: templates = [], refetch } = useGetAllEventTemplatesQuery();

  return (
    <div className="w-full">
      <NewEventTemplateForm refetch={refetch} />
      <TemplateList templates={templates} refetch={refetch} />
    </div>
  );
};

export default EventTemplate;
