import { ErrorMessage } from '@hookform/error-message';
import { Paper, TextField } from '@material-ui/core';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useGetAllEventsQuery } from '../../utilities/rtkquery';
import { createDisplayLink } from '../../utilities/helper';
import { Autocomplete, Button, MenuItem, Select } from '@mui/material';
import { CreateEventProps, createEventTemplate, fileUpload } from '../../utilities/servicesNew';
import { useToasts } from 'react-toast-notifications';

interface TemplateProps {
  name: string;
  description: string;
  event: {
    id: string;
    label: string;
  } | null;
}

interface NewEventTemplateProps {
  refetch: () => void;
}

const NewEventTemplate: React.FC<NewEventTemplateProps> = ({ refetch }) => {
  const { data: events = [] } = useGetAllEventsQuery({
    onlyFuture: false,
    limit: 1000,
    population: [],
    includePrivate: true,
    project: { slug: 1, name: 1 },
  });

  const [previewImage, setPreviewImage] = useState('');
  const { addToast } = useToasts();
  const {
    handleSubmit,
    register,
    reset,
    setValue,
    formState: { errors },
  } = useForm<TemplateProps>();

  async function handleImageUpload(files: FileList | null) {
    if (!files || !files[0]) return;
    const file = await fileUpload(files[0]);
    setPreviewImage(file.data.fileId);
    refetch();
  }

  async function handleCreateTemplate(props: TemplateProps) {
    const sendProps: Partial<CreateEventProps> = { ...props, eventId: props?.event?.id ?? undefined };
    if (previewImage) sendProps.previewImage = previewImage;
    createEventTemplate(sendProps)
      .then(() => {
        refetch();
        reset();
      })
      .catch((err) => {
        addToast(err.message);
      });
  }

  const textValidation = { required: 'Required!', minLength: { value: 2, message: 'Min 2 characters!' } };

  const sortedEvents = [...events].sort((a, b) => a.slug.localeCompare(b.slug));

  return (
    <div className="w-full">
      <Paper className="p-4 w-64">
        <h1 className="text-center text-xl">New template</h1>
        <div className="flex justify-center">
          <img alt="avatar" className="w-32 h-32 my-2" src={createDisplayLink(previewImage)} />
        </div>
        <form onSubmit={handleSubmit(handleCreateTemplate)}>
          <input className="text-center" type="file" onChange={(e) => handleImageUpload(e.target.files)} />
          <TextField
            style={{ margin: '0.5rem 0' }}
            variant="outlined"
            {...register('name', textValidation)}
            placeholder="Name"
            name="name"
            className="w-full"
          />
          <ErrorMessage errors={errors} name="name" />
          <TextField variant="outlined" {...register('description', textValidation)} placeholder="Description" className="w-full" />
          <ErrorMessage errors={errors} name="description" />
          <Autocomplete
            disablePortal
            id="event-select"
            className="my-2"
            renderInput={(params) => <TextField {...params} variant="outlined" label="Event" />}
            options={sortedEvents.map(({ name, _id, slug }) => ({ label: `${name ?? '[No Name]'} (${slug})` ?? slug ?? '', id: _id ?? '' })) ?? []}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            {...register('event')}
            onChange={(e, value) => setValue('event', value)}
          />
          <Button className="w-full my-2" type="submit">
            CREATE
          </Button>
        </form>
      </Paper>
    </div>
  );
};

export default NewEventTemplate;
