import { format } from 'date-fns';
import { de } from 'date-fns/locale';

export function delete_cookie(name: string, path?: string, domain?: string) {
  function getAppCookie(name: string) {
    const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
    return v ? v[2] : null;
  }

  if (getAppCookie(name)) {
    document.cookie = `${name}=${path ? `;path=${path}` : ''}${domain ? `;domain=${domain}` : ''};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  }
}

export function setCookie(name: string, content: string, duration?: number) {
  document.cookie = `${name}=${content};max-age=${duration || 604800}`;
}

export function formatUTCDate(date: Date, formatString = 'dd.MM.yyyy HH:mm') {
  if (date) {
    // return format(addMinutes(date, date.getTimezoneOffset()), formatString);
    return format(date, formatString, { locale: de });
  }
  throw new Error('Date not set!');
}

export function createDisplayLink(
  id: string | undefined | null,
  returnPlaceholder = true,
  width?: number,
  height?: number,
  quality?: number,
  optimized = true
): string {
  const apiURL = ((process.env.baseURL as string) + process.env.basePath) as string;
  if (id === '/logo.png' || id === 'logo.png') return '/logo.png';

  if (optimized && id) {
    return imageKitUrl(id, width, height, quality);
  }

  if (id) {
    return `${apiURL}file/display/${id}${width && height ? `?w=${width}&h=${height}&q=80` : ``}`;
  }
  if (returnPlaceholder) return '/logo.png';
  return '';
}

export function numberWithThousandPoint(x: number) {
  try {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  } catch (e) {
    return x?.toString() || x;
  }
}

export function openWindowWithPost(url: string, data: Record<string, string>) {
  const form = document.createElement('form');
  form.target = '_blank';
  form.method = 'POST';
  form.action = url;
  form.style.display = 'none';

  for (const key in data) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = typeof data[key] === 'object' ? JSON.stringify(data[key]) : data[key];
    form.appendChild(input);
  }

  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

export const imageKitUrl = (imageId: string, width?: number, height?: number, quality?: number) => {
  if (!imageId || imageId === '') return '/logo.png';
  return `https://static.vystem.io/${imageId}?tr=${width ? `w-${width},` : `w-2500,c-at_max,`}${height ? `h-${height},` : ``}${quality ? `q-${quality}` : ``}`;
};

export const imageKitLoader = ({ src, width, quality }: { src: string; width: number; quality?: number }) => {
  return createDisplayLink(src, true, width, undefined, quality);
};
