import { Button, Checkbox, FormControlLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RestoreMeetingroomModal } from '../../components/event/RestoreMeetingroomModal';
import { EventInterface, Organization } from '../../utilities/interfaces';
import { useParams } from 'react-router-dom';
import http from '../../utilities/http';
import AdminLayout from '../../components/AdminLayout';

interface VideoType {
  value: string;
  displayname: string;
}

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: '500px',
    width: '100%',
    padding: '1rem',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {},
  },
  horizontal: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      maxWidth: '47%',
    },
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  previewImage: {
    maxWidth: '100%',
    margin: '0.5rem 0',
  },
  nativeSelect: {
    padding: '18.5px 14px',
    marginTop: '0.5rem',
    borderRadius: '4px',
  },
  accordion: {
    margin: '10px 0',
  },
  update: {
    marginTop: '10px',
  },
  msg: {
    marginTop: '5px',
    marginBottom: '100px',
  },
  extra: {
    display: 'flex',
    flexDirection: 'column',
  },
  expandButton: {
    marginTop: '10px',
  },
}));

interface EventImages {
  logo?: string;
}

const uiTemplates = ['vystem-dark', 'vystem-light', 'vystem-zukunftsrat'];

const EditEvent: React.FC = () => {
  const { id: eventid } = useParams<{ id: string }>();
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [msg, setMsg] = useState('');
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [images, setImages] = useState<EventImages>({});
  const classes = useStyles();
  const [defaultEvent, setDefaultEvent] = useState<EventInterface | null>(null);
  const [userLevel, setUserLevel] = useState(1);
  const [restoreModalOpen, setRestoreModalOpen] = useState<boolean>(false);

  useEffect(() => {
    setUserLevel(parseInt(localStorage.getItem('role') as string));
    if (userLevel === 0) {
      http.post('/organization/getall').then(({ data }) => {
        setOrganizations(data.data);
      });
    }
    http.post('/event/get', { eventid }).then(({ data }) => {
      setDefaultEvent(data.data);
    });
  }, []);

  function updateEvent(data: EventInterface) {
    if (!defaultEvent) return;
    const { logo } = defaultEvent.template;

    const fees: { pricePerTransaction?: number; volumePercentage?: number } = {};
    if (data.modules.tickets?.fees) {
      fees['pricePerTransaction'] = parseFloat(data.modules.tickets?.fees.pricePerTransaction);
      fees['volumePercentage'] = parseFloat(data.modules.tickets?.fees.volumePercentage);
    }

    data.slug = data.slug.trim();
    const sendData = {
      eventid,
      ...data,
      template: {
        ...data.template,
        logo,
        ...images,
      },
      modules: {
        ...data.modules,
        tickets: {
          ...data.modules.tickets,
          fees,
        },
      },
    };

    http
      .post('/event/update', sendData)
      .then((res) => {
        setMsg('Success!');
      })
      .catch((err) => {
        console.log(err);
        setMsg('Something went wrong, see console.');
      });
  }

  if (!defaultEvent)
    return (
      <AdminLayout>
        <h1>Loading...</h1>
      </AdminLayout>
    );
  return (
    <AdminLayout>
      <div className={classes.center}>
        <div className={classes.paper}>
          <form className={classes.form} onSubmit={handleSubmit(updateEvent)} noValidate>
            <p className="mb-5 text-2xl font-bold">{defaultEvent.name}</p>
            <div className="flex flex-col space-y-2">
              <p>Ticket Fees: </p>
              <TextField
                variant="outlined"
                id="outlined-number"
                {...register('modules.tickets.fees.pricePerTransaction')}
                label="Price per Transaction"
                type="number"
                defaultValue={defaultEvent.modules.tickets?.fees?.pricePerTransaction}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                variant="outlined"
                defaultValue={defaultEvent.modules.tickets?.fees?.volumePercentage}
                id="outlined-number"
                {...register('modules.tickets.fees.volumePercentage')}
                label="Volume Fee %"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            Modules
            <FormControlLabel
              aria-label="Acknowledge"
              control={<Checkbox {...register('modules.guestmanagement.active')} defaultChecked={defaultEvent.modules.guestmanagement.active} />}
              label="Guest Management"
            />
            <FormControlLabel
              aria-label="Acknowledge"
              control={
                <Checkbox
                  defaultChecked={defaultEvent.modules.guestmanagement.settings.authWithoutEmail}
                  {...register('modules.guestmanagement.settings.authWithoutEmail')}
                />
              }
              label="Teilnahme ohne E-Mail Adresse ⚠️"
            />
            <FormControlLabel
              aria-label="Acknowledge"
              control={<Checkbox {...register('modules.customTracking.active')} defaultChecked={defaultEvent.modules.customTracking.active} />}
              label="Custom Tracking"
            />
            <FormControlLabel
              aria-label="Acknowledge"
              control={<Checkbox {...register('modules.templatemanagement.active')} defaultChecked={defaultEvent.modules.templatemanagement.active} />}
              label="Template Management"
            />
            <FormControlLabel
              aria-label="Acknowledge"
              control={
                <Checkbox
                  defaultChecked={defaultEvent.modules.templatemanagement.settings.templateEditor}
                  {...register('modules.templatemanagement.settings.templateEditor')}
                />
              }
              label="Template Editor (for this also the Template Management needs to be active)"
            />
            <FormControlLabel
              aria-label="Acknowledge"
              control={<Checkbox {...register('modules.qacontrol.active')} defaultChecked={defaultEvent.modules.qacontrol.active} />}
              label="QA-Control"
            />
            <FormControlLabel
              aria-label="Acknowledge"
              control={<Checkbox {...register('modules.preuploadedmedia.active')} defaultChecked={defaultEvent.modules.preuploadedmedia.active} />}
              label="Media"
            />
            <FormControlLabel
              aria-label="Acknowledge"
              control={<Checkbox {...register('modules.feedbackEditing.active')} defaultChecked={defaultEvent.modules.feedbackEditing.active} />}
              label="Feedback"
            />
            <FormControlLabel
              aria-label="Acknowledge"
              control={<Checkbox {...register('modules.booking.active')} defaultChecked={defaultEvent.modules.booking.active} />}
              label="Appointment Booking"
            />
            <FormControlLabel
              aria-label="Acknowledge"
              control={<Checkbox {...register('modules.directorsControl.active')} defaultChecked={defaultEvent.modules.directorsControl.active} />}
              label="Directors-Control"
            />
            <FormControlLabel
              aria-label="Acknowledge"
              control={<Checkbox {...register('modules.networking.active')} defaultChecked={defaultEvent.modules.networking.active} />}
              label="Video conference"
            />
            <FormControlLabel
              aria-label="Acknowledge"
              control={<Checkbox {...register('modules.privileges.active')} defaultChecked={defaultEvent.modules.privileges.active} />}
              label="Privileges"
            />
            <FormControlLabel
              aria-label="User Reporting"
              control={<Checkbox {...register('modules.userReporting.active')} defaultChecked={defaultEvent.modules.userReporting.active} />}
              label="User Reporting"
            />
            <FormControlLabel
              aria-label="Livestreams"
              control={<Checkbox {...register('modules.livestreams.active')} defaultChecked={defaultEvent.modules.livestreams.active} />}
              label="Livestreams"
            />
            <FormControlLabel
              aria-label="Acknowledge"
              control={
                <Checkbox
                  defaultChecked={defaultEvent.modules.preuploadedmedia?.settings?.boothVideoUpload}
                  {...register('modules.preuploadedmedia.settings.boothVideoUpload')}
                />
              }
              label="Booth video upload"
            />
            <FormControlLabel
              aria-label="Acknowledge"
              control={<Checkbox {...register('modules.challenges.active')} defaultChecked={defaultEvent.modules.challenges.active} />}
              label="Challenges"
            />
            <FormControlLabel
              aria-label="Acknowledge"
              control={<Checkbox {...register('modules.photobooths.active')} defaultChecked={defaultEvent.modules.photobooths.active} />}
              label="Photobooths"
            />
            <FormControlLabel
              aria-label="Acknowledge"
              control={<Checkbox {...register('modules.studios.active')} defaultChecked={defaultEvent.modules.studios.active} />}
              label="Studios"
            />
            <FormControlLabel
              aria-label="Acknowledge"
              control={<Checkbox {...register('modules.analytics.active')} defaultChecked={defaultEvent.modules.analytics.active} />}
              label="Analytics"
            />
            <FormControlLabel
              aria-label="Acknowledge"
              control={<Checkbox {...register('proAnalytics')} defaultChecked={defaultEvent.proAnalytics} />}
              label="Pro Analytics"
            />
            <FormControlLabel
              aria-label="Acknowledge"
              control={<Checkbox {...register('modules.app.active')} defaultChecked={defaultEvent.modules.app.active} />}
              label="App"
            />
            <FormControlLabel
              aria-label="Acknowledge"
              control={<Checkbox {...register('modules.matching.active')} defaultChecked={defaultEvent.modules.matching.active} />}
              label="Matching"
            />
            <TextField variant="outlined" margin="normal" label="Slug" {...register('slug')} color="secondary" defaultValue={defaultEvent.slug} />
            <Select color="secondary" {...register('template.uiTemplate')} defaultValue={defaultEvent.template.uiTemplate}>
              {uiTemplates.map((uiTemplate) => (
                <MenuItem value={uiTemplate}>{uiTemplate}</MenuItem>
              ))}
            </Select>
            <RestoreMeetingroomModal open={restoreModalOpen} onClose={() => setRestoreModalOpen(false)} eventId={eventid} />
            <Button className={classes.update} fullWidth variant="outlined" color="secondary" onClick={() => setRestoreModalOpen(true)}>
              Restore Meetingrooms
            </Button>
            <Button className={classes.update} type="submit" fullWidth variant="contained" color="secondary">
              Update Event
            </Button>
            <p style={{ textAlign: 'center' }} className={classes.msg}>
              {msg}
            </p>
          </form>
        </div>
      </div>
    </AdminLayout>
  );
};

export default EditEvent;
