import axios from 'axios';
import { getToken, removeToken } from './auth';
import history from './history';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 30000,
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response?.status === 403) {
      removeToken();
      history.push('/login');
    }
    return Promise.reject(error);
  }
);

instance.interceptors.request.use(
  function (config) {
    const token = getToken();
    config.headers.authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default instance;
