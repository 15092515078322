import { ReactElement } from 'react';

/**
 * based on this https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2
 * @param children the child element
 * @param condition the boolean when the wrapper should be applied
 * @param wrapper the wrapper for the children
 * @constructor
 *
 * an example implementation can be found here
 * <ConditionalWrapper
 * condition={!disabled}
 * wrapper={(children: ReactElement) => <Link href={item.href}>{children}</Link>}
 * >
 * <p>Servus</p>
 * </ConditionalWrapper>
 */
export const ConditionalWrapper = ({
  condition,
  wrapper,
  children,
}: {
  condition: boolean;
  wrapper: (children: ReactElement) => ReactElement;
  children: ReactElement;
}) => (condition ? wrapper(children) : children);
