import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button } from '@mui/material';
import React, { useState } from 'react';
import VyModal from './VyModal';

interface VyConfirmProps {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onConfirm?: () => void;
  onDeny?: () => void;
  onClose?: () => void;
  onConditionalConfirm?: () => Promise<boolean>;
  title?: string;
  message?: string;
  confirmText?: string;
  warningText?: string;
  denyText?: string;
  disabled?: boolean;
  children: React.ReactFragment;
  className?: string;
  defaultOpen?: boolean;
  skipConfirmation?: boolean;
}

export const VyConfirm = ({
  onClick,
  onConfirm,
  disabled,
  onDeny,
  onClose,
  onConditionalConfirm,
  children,
  confirmText,
  denyText,
  title,
  message,
  warningText,
  className,
  defaultOpen = false,
  skipConfirmation = false,
}: VyConfirmProps) => {
  const [open, setOpen] = useState(defaultOpen);

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  const handleClick = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (disabled) {
      // do nothing on disabled
    } else if (onConditionalConfirm) {
      const requireConfirm = await onConditionalConfirm();
      if (requireConfirm) {
        setOpen(true);
        onClick?.(e);
      } else {
        onConfirm?.();
        return;
      }
    } else {
      e.stopPropagation();
      if (skipConfirmation) {
        onConfirm?.();
        return;
      }
      setOpen(true);
      onClick?.(e);
    }
  };

  return (
    <>
      <VyModal open={open} onClose={handleClose}>
        {(onClose) => (
          <div>
            <p className="text-left text-slate-900 text-xl font-bold mb-1">{title || 'Confirm'}</p>
            <p className="text-left text-slate-600">{message || 'Are you sure you want to proceed with this action?'}</p>
            {warningText && <Alert severity="warning">{warningText}</Alert>}
            <div className="flex flex-col sm:flex-row justify-between mt-5 sm:space-x-2">
              <Button
                className="w-full"
                startIcon={<FontAwesomeIcon icon={faCheck} />}
                onClick={() => {
                  onClose();
                  onConfirm?.();
                }}>
                {confirmText || 'Confirm'}
              </Button>
              <Button
                className="w-full mt-2 sm:mt-0"
                onClick={() => {
                  onClose();
                  onDeny?.();
                }}>
                {denyText || 'Deny'}
              </Button>
            </div>
          </div>
        )}
      </VyModal>
      <div
        className={`${disabled ? '' : 'cursor-pointer'} ${className ? className : ''}`}
        onClick={async (e) => {
          e.stopPropagation();
          await handleClick(e);
        }}>
        {children}
      </div>
    </>
  );
};
