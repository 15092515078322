import { configureStore } from '@reduxjs/toolkit';
import { defaultApi } from './utilities/rtkquery';

export const store = configureStore({
  reducer: {
    [defaultApi.reducerPath]: defaultApi.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(defaultApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
