import AdminLayout from '../../components/AdminLayout';
import { Card, CardContent, Typography } from '@material-ui/core';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';

const ChangeLogComponent: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);

  async function fetch() {
    setIsLoading(true);
    try {
      const { data: data } = await axios.get('/api/v1/config/changelog');
      console.log(data);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    fetch();
  }, []);

  return (
    <AdminLayout>
      <div className="flex flex-col">
        {/*changeLog.map((item) => 
			<Card> 
				<CardContent>  
				<Typography gutterBottom variant="h5" component="div">{item.title}</Typography> 
				{item.description}
				</CardContent>
			</Card>
		)*/}
      </div>
    </AdminLayout>
  );
};

export default ChangeLogComponent;
