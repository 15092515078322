import { Button, Checkbox, FormControlLabel, Switch, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { EventInterface } from '../../utilities/interfaces';
import { useParams } from 'react-router-dom';
import http from '../../utilities/http';
import AdminLayout from '../../components/AdminLayout';

const useStyles = makeStyles(() => ({
  paper: {
    maxWidth: '500px',
    width: '100%',
    padding: '1rem',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > div': {},
  },
  horizontal: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > *': {
      maxWidth: '47%',
    },
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
  },
  previewImage: {
    maxWidth: '100%',
    margin: '0.5rem 0',
  },
  nativeSelect: {
    padding: '18.5px 14px',
    marginTop: '0.5rem',
    borderRadius: '4px',
  },
  accordion: {
    margin: '10px 0',
  },
  update: {
    marginTop: '10px',
  },
  msg: {
    marginTop: '5px',
    marginBottom: '100px',
  },
  extra: {
    display: 'flex',
    flexDirection: 'column',
  },
  expandButton: {
    marginTop: '10px',
  },
}));

const DuplicateEvent: React.FC = () => {
  const { id: eventid } = useParams<{ id: string }>();
  const [msg, setMsg] = useState('');
  const [withScope, setWithScope] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm();
  const classes = useStyles();
  const [defaultEvent, setDefaultEvent] = useState<EventInterface | null>(null);

  useEffect(() => {
    http.post('/event/get', { eventid }).then(({ data }) => {
      setDefaultEvent(data.data);
    });
  }, []);

  function updateEvent(data: Record<string, string>) {
    http
      .post('/event/duplicate', { eventid, ...data })
      .then(() => {
        setMsg('Success!');
      })
      .catch((err) => {
        console.log(err);
        setMsg('Something went wrong, see console.');
      });
  }

  if (!defaultEvent)
    return (
      <AdminLayout>
        <h1>Loading...</h1>
      </AdminLayout>
    );
  return (
    <AdminLayout>
      <div className={classes.center}>
        <div className={classes.paper}>
          <form className={classes.form} onSubmit={handleSubmit(updateEvent)} noValidate>
            <p className="text-2xl font-bold mb-5">{defaultEvent.name}</p>
            <TextField
              type="number"
              variant="outlined"
              margin="normal"
              label="Amount of copies ( Maximum 5 )"
              color="secondary"
              defaultValue={1}
              {...register('amount', {
                valueAsNumber: true,
                validate: (v) => v < 6,
              })}
              inputProps={{ max: 5 }}
            />
            <FormControlLabel
              aria-label="Acknowledge"
              control={<Switch value={withScope} onChange={(e) => setWithScope(e.target.checked)} />}
              label="With scope"
            />
            {withScope && (
              <>
                Scopes
                <FormControlLabel aria-label="Acknowledge" control={<Checkbox {...register('scope.pages')} defaultChecked />} label="Pages" />
                <FormControlLabel aria-label="Acknowledge" control={<Checkbox {...register('scope.booths')} defaultChecked />} label="Booths" />
                <FormControlLabel
                  aria-label="Acknowledge"
                  control={<Checkbox {...register('scope.eventregistrationfields')} defaultChecked />}
                  label="Registration Fields"
                />
                <FormControlLabel
                  aria-label="Acknowledge"
                  control={<Checkbox {...register('scope.accessrules')} defaultChecked />}
                  label="Hybrid Access Rules"
                />
                <FormControlLabel
                  aria-label="Acknowledge"
                  control={<Checkbox {...register('scope.emailtemplates')} defaultChecked />}
                  label="E-Mail Templates"
                />
                <FormControlLabel aria-label="Acknowledge" control={<Checkbox {...register('scope.networking')} defaultChecked />} label="Video conference" />
                <FormControlLabel aria-label="Acknowledge" control={<Checkbox {...register('scope.privileges')} defaultChecked />} label="Privileges" />
              </>
            )}
            <Button className={classes.update} type="submit" disabled={isSubmitting} fullWidth variant="contained" color="secondary">
              Duplicate Event
            </Button>
            <p style={{ textAlign: 'center' }} className={classes.msg}>
              {msg}
            </p>
          </form>
        </div>
      </div>
    </AdminLayout>
  );
};

export default DuplicateEvent;
